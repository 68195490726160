import slugify from 'slugify';
import Hashids from 'hashids';

const skoob_title_slug = 'Skoob Page';
const story_title_slug = 'Story Page';

export const hashids = new Hashids('rbsl:', 12); // Minimum length 8


export const slugs = {
    skoob_page_slug: slugify(skoob_title_slug, { lower: true, strict: true }),
    story_page_slug: slugify(story_title_slug, { lower: true, strict: true }),
  };
