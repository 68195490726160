import React, { useEffect, useState, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  Typography,
  styled,
  ListItemButton,
} from '@mui/material';
import DrawerItem from './DrawerItem';
import { Link as ScrollLink} from 'react-scroll';
// import SkooobIcon from '../assets/SkoobIcon.svg';
// import SkooobIcon from '../assets/SkoobLogo.svg';
import SkooobLogo from '../assets/SkoobLogo.png';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import { UriContext } from '../pages/Main';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: 'Josefin Sans',
  marginRight: '12px',
  marginLeft:'12px',
  paddingBlock:'20px'
});

const ListMenu = styled(List)(({ theme }) => ({
  display: 'none',
  // [theme.breakpoints.up('md')]: {
  //   display: 'flex',
  //   fontFamily: 'Josefin Sans',

  // },
}));

const itemList = [
  {
    text: 'Home',
    to: 'Home', // replace with the ID of the target component
  },
  {
    text: 'About',
    to: 'Features', // replace with the ID of the target component
  },
  {
    text: 'Features',
    to: 'AboutMindverse', // replace with the ID of the target component
  },
  {
    text: 'Why',
    to: 'Services', // replace with the ID of the target component
  },
  {
    text: 'How',
    to: 'HowItWorks', // replace with the ID of the target component
  },
];

const UpdatedNavbar = () => {

  // const [uri,setUri]=useState('');
  const uri = useContext(UriContext);

  // useEffect(()=>{
  //   console.log("set uri worked in navbar +++++");
  //   setUri(localStorage.getItem('url'));
  // },[])

const handleClick = (event) => {
    try {
      ReactGA.event({
        category: "download_clicks",
        action: "Download_TopNavBar",
        label: 'top_navbar_download_button',
      });
  
    } catch (error) {
      // console.error("error:", error);
    }
  };

  
  return (
    <AppBar
      component="nav"
      position="sticky"
    
      sx={{

        backgroundColor: '#181819',
        width: '100vw',
        maxWidth: '600px'
      }}
      style={{boxShadow:'none'}}
    >
      <StyledToolbar sx={{zIndex:3000, }} >
         <Box sx={{ 

                    marginLeft:'0px',
                    maxWidth:'108px',
                    width:'108px',
                    height:'48px'
                }}
                >
             <img
                      src={SkooobLogo}
                      alt="SkoobLogo"
                      style={{
                      width:'100%',
                      height:'auto',
                      imageRendering: 'optimizeSpeed', // Improve performance
                      imageRendering: '-webkit-optimize-contrast', 
                      transform: 'translateZ(0)', 
                      backfaceVisibility: 'hidden',
            }}
          />
        </Box>
        <Box sx={{borderRadius:'42px', display: 'flex',
                justifyContent: 'center', /* Center horizontally */
                alignItems: 'center',position:'absolute',right:{xs:'50px',sm:'80px'},backgroundColor:'#925EFF',maxWidth:'127px',width:{xs:'40vw',sm:'20vw'},height:'36px',}}>
              <a href={uri} onClick={handleClick} style={{textDecoration:'none',}} target="_blank">
                <Typography
                    style={{ fontFamily: 'Open Sans',
                    fontWeight:'600',color:'#FFFFFF',fontSize: 12
                  }}
                  >Download App
                </Typography>
              </a>
        </Box>
        <Box sx={{ display: 'block',fontFamily: 'Josefin Sans'  }}>
          <DrawerItem />
        </Box>
        {/* <ListMenu>
          {itemList.map((item) => {
            const { text, to } = item;
            return (
              <ListItem key={text}>
                
                <ScrollLink
                  to={to}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-200}
                  activeClass="active" // Apply a class to the active link
                >
                  <ListItemButton
                    onClick={()=>navigate('/')}
                    sx={{
                      color: '#fff',
                      fontSize: 18,
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#0d6efd',
                      },
                    }}
                  >
                    <Typography sx={{   fontFamily: 'Open Sans',
                                fontWeight: 700,fontSize: 18 }}>{text}</Typography>
                  </ListItemButton>
                </ScrollLink>
              </ListItem>
            );
          })}
        </ListMenu> */}
      </StyledToolbar>
    </AppBar>
  );
};

export default UpdatedNavbar;
