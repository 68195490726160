import {React,useEffect,useState,useRef,useContext} from 'react';
import {
    Box,
    Skeleton,
    Typography,
    CircularProgress
} from '@mui/material';
import '@fontsource/montserrat/500.css';
import { keyframes } from '@emotion/react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web';
import axios from 'axios';
import ReactGA from "react-ga4";
import { UriContext } from '../pages/Main';

const About = () => {

        const loadingAnimation = keyframes`
            0% { left: 0%; }
            40% { left: 20%; }
            80% { left: 80%; }
            100% { left: 100%; }
        `;
        
        const uri = useContext(UriContext);
        const [TrendingCollections,setTrendingCollections] = useState([]);
        const [NewReleasesCollections,setNewReleasesCollections] = useState([]);
        const [Loaders,setLoaders] = useState({topSkoobs:false,newReleases:false})
        const TrendingScrollContainerRef = useRef(null);
        const NewReleaseScrollContainerRef = useRef(null);
        const TrendingScrollRef = useRef(0);
        const NewReleaseScrollRef = useRef(0);
        // const [uri,setUri]=useState('');

        const handleClick = (event) => {
            try {
              ReactGA.event({
                category: "download_clicks",
                action: "Download_Skoob",
                label: 'about_skoob_section',
              });
          
            } catch (error) {
            //   console.error("error:", error);
            }
          };
      
        const handleScroll = (ContainerRef,CollectionsList,ScrollRef) => {
            if (!ContainerRef.current) return;
            const scrollLeft = ContainerRef.current.scrollLeft;
            const scrollAmount = scrollLeft <= 180 ? 180 : 169
            let index = Math.round(scrollLeft / scrollAmount); 
            
            if(index === CollectionsList?.length - 2 && ContainerRef.current.scrollLeft > index*169)
                {   
                    index = CollectionsList?.length - 1
                }
                ScrollRef.current = index;
           
            };
          
         
          const scrollToIndex = (index,ContainerRef,CollectionsList) => {
              if (!ContainerRef.current || CollectionsList.length === 0) return;
              
              const scrollAmount = index === 1 ? 180 : index === 0 ? 179 : 181 + (index-1)*169; // Width of each item + margin
                  
              ContainerRef.current.scrollTo({
              left: scrollAmount,
              behavior: "smooth",
              });
          };
          
        useEffect(() => {
                  if (!TrendingScrollContainerRef.current) return; // Ensure ref is assigned
                  if (TrendingCollections.length === 0) return;
                  
                  
                      const interval = setInterval(() => {
                          
                          const nextIndex = (TrendingScrollRef.current + 1) % TrendingCollections.length;
                          scrollToIndex(nextIndex,TrendingScrollContainerRef,TrendingCollections);
                          TrendingScrollRef.current = nextIndex;
                          
                      }, 5000);
              
                  return () => clearInterval(interval); // Cleanup interval on unmount
      
      
              }, [TrendingCollections]); // Dependency ensures updates

        
        useEffect(() => {
            if (!NewReleaseScrollContainerRef.current) return; // Ensure ref is assigned
            if (NewReleasesCollections.length === 0) return;
    
                const interval = setInterval(() => {
                    
                    const nextIndex = (NewReleaseScrollRef.current + 1) % NewReleasesCollections.length;
                    scrollToIndex(nextIndex,NewReleaseScrollContainerRef,NewReleasesCollections);
                    NewReleaseScrollRef.current = nextIndex;
                    
                }, 4000);
        
            return () => clearInterval(interval);


        }, [NewReleasesCollections]); 




        const FetchTrendingCollections = async () => {
              setLoaders(prev => ({...prev,topSkoobs:true}))
            //   const BACKEND_URL = localStorage.getItem('BACKEND_URL');
              const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
              const user_id = process.env.REACT_APP_USER_ID;
              await axios.get(`${BACKEND_URL}/collection/trending`, {
                headers: {
                  'Authorization': user_id
                }
              },)
                .then(response => {
                  // console.log(response.data);

                  setTrendingCollections(response.data);
                 
                  setLoaders(prev => ({...prev,topSkoobs:false}))
            
                })
                .catch(error => {
                //   console.error('error',error);
                  setLoaders(prev => ({...prev,topSkoobs:false}))
                });
            }
          
        async function FetchNewReleasesCollections() {
              setLoaders(prev => ({...prev,newReleases:true}))
            //   const BACKEND_URL = localStorage.getItem('BACKEND_URL');
              const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
              const user_id = process.env.REACT_APP_USER_ID;
              await axios.get(`${BACKEND_URL}/collection/newrelease`, {
                headers: {
                  'Authorization': user_id
                }
              },)
                .then(response => {
                  // console.log(response.data);

                  setNewReleasesCollections(response.data);
              
                  setLoaders(prev => ({...prev,newReleases:false}))
            
                })
                .catch(error => {
                //   console.log("failed to load")
                  setLoaders(prev => ({...prev,newReleases:false}))
                });
          }
      
      
          
        useEffect(()=>{
              FetchTrendingCollections();
              FetchNewReleasesCollections();
            //   setUri(localStorage.getItem('url'));
            },[])


      const [ref, inView] = useInView({
        triggerOnce: true,
      });
      const springProps = useSpring({
        from: { opacity: 0, transform: 'translateY(50%)' },
        to: async (next) => {
          await next({ opacity: 1, transform: 'translateY(0%)' });
        },
        config: { duration: 500 },
        immediate: !inView,
        reset: true,
      });
    return (
        
       <Box ref = {{ref}}
                sx={{
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'start',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#181819',
                    textAlign:'center',
                    top:'1px'
                    
                }}
                >
                {/* Gradient Box */}
                <Box
                    sx={{
                    // border:'2px solid blue',
                    borderRadius: '50%',
                    maxWidth:'261px',
                    width: '80vh',
                    height: '80vh',
                    maxHeight:'261px',
                    position: 'absolute',
                    top: 0,
                    background: `linear-gradient(
                        to right,
                        rgba(71, 224, 255) 0%,      
                        rgba(106, 209, 233) 36%,
                        rgba(146, 94, 255) 66%,
                        rgba(124, 55, 228) 100%   
                    )`,
                    filter: 'blur(110px)',
                    transform: 'translateZ(0)', 
                    willChange: 'transform, filter',
                    }}
                />

                {/* Text Box */}
                <Box id="Features"
                    style={{
                    position: 'relative',
                    top:'0px',
                    zIndex: 1, 
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth:'500px',
                    width:'90vw',
                    alignItems: 'center', 
                    justifyContent:'center',
                    marginTop:'70px',
                    gap:'5px',
                    overflow:'hidden',
                    paddingInline:'10px',
                    }}
                >

                    <Typography
                        variant="h2"
                        component="h2"
                        style={{
                            color: '#fff',
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: '600',
                            lineHeight: '36px',
                            letterSpacing: '4%',
                            fontSize: '24px',
                        }}
                    >
                    1000+ Podcasts for Free
                    </Typography>

                    {/* Subheading */}
                    <Typography
                        variant="h4"
                        component="p"
                        sx={{
                            color: '#fff',
                            lineHeight: '24px',
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: '500',
                            letterSpacing: '5%',
                            fontSize: '14px',
                        }}
                    >
                        Dive into a wide range of topics, expertly curated for your learning journey.
                    </Typography>
                </Box>

                <Box style={{
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent:'center',
                    marginTop:'40px',
                    gap:'7px',
                    maxWidth:'600px',
                    width:'100%',
                    overflow:'hidden',
                    marginLeft: 0,
                    }}>
                    {
                    Loaders?.topSkoobs === true ?
                    (
                        <Box
                        sx={{
                            width: '90%',
                            maxWidth:'600px',
                            height: '154px', 
                            marginInline: '25px', 
                            marginBottom:'5px',
                            border:'0.5px solid grey',
                            borderRadius:'16px',
                            backgroundSize: '200% 100%',
                            position:'relative'
                            
                        }}
                        > 
                        <Skeleton  animation='wave' variant="rounded" width='100%' height='100%' />
                        </Box>
                    )
                    :
                    <Box
                        className = 'hide-scrollbar'
                        ref={TrendingScrollContainerRef}
                        sx={{
                            width: '100%',
                            overflowX: 'auto', 
                            whiteSpace: 'nowrap', 
                            paddingLeft: '25px', 
                            scrollBehavior: 'smooth',
                        }}
                        onScroll = {()=>handleScroll(TrendingScrollContainerRef,TrendingCollections,TrendingScrollRef)}
                        >
                        {(Array.isArray(TrendingCollections)) && TrendingCollections.map((collection,index) => (
                            <Box
                            key={collection.id}
                            sx={{
                                display: 'inline-block', 
                                marginRight: index === TrendingCollections?.length - 1 ? '25px' : '15px', // Add spacing between items
                                textAlign: 'center',
                                
                            }}
                            >
                            <a href={uri} onClick={handleClick} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={collection?.img_url}
                                    alt={collection?.title}
                                    style={{
                                    width: '154px', 
                                    height: '154px', 
                                    borderRadius: '16px', // Rounded corners
                                    border:'0.8px solid #FFFFFF'
                                    }}
                                />
                            </a>
                     
                            </Box>
                        ))}
                        </Box>
                        }
                    
                    {
                    Loaders?.newReleases === true ?
                    (
                        <Box
                        sx={{
                            width: '90%',
                            maxWidth:'600px',
                            height: '154px', 
                            marginInline: '25px', 
                            marginBottom:'5px',
                            border:'0.5px solid grey',
                            borderRadius:'16px',
                            backgroundSize: '200% 100%',
                            animation: 'loadingAnimation 1.5s infinite linear',
                        }}
                        > <Skeleton  animation='wave' variant="rounded" width='100%' height='100%' />
                        </Box>
                    )
                    :
                    <Box
                        className = 'hide-scrollbar'
                        ref={NewReleaseScrollContainerRef}
                        style={{
                            width: '100%', 
                            overflowX: 'auto', 
                            whiteSpace: 'nowrap', 
                            paddingLeft: '25px', 
                            scrollBehavior: 'smooth'
                        }}
                        onScroll = {()=>handleScroll(NewReleaseScrollContainerRef,NewReleasesCollections,NewReleaseScrollRef)}
                        >
                        {/* Render TrendingCollections */}
                        {(Array.isArray(NewReleasesCollections)) && NewReleasesCollections?.map((collection,index) => (
                            <Box
                            key={collection.id}
                            sx={{
                                display: 'inline-block', // Display items in a row
                                marginRight: index === NewReleasesCollections?.length - 1 ? '25px' : '15px',
                                textAlign: 'center', // Center content
                            }}
                            >
                            <a href={uri} onClick={handleClick} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={collection?.img_url}
                                    alt={collection?.title}
                                    style={{
                                    width: '154px', 
                                    height: '154px', 
                                    borderRadius: '16px', 
                                    border:'0.8px solid #FFFFFF'
                                    }}
                                />
                            </a>
                           
                            </Box>
                        ))}
                        </Box>
                    }
                </Box>
            </Box>

    )
}

export default About;



