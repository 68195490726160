import React, { useEffect ,useState, useRef} from "react";
import axios from 'axios';
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import DualBackground from "../assets/DualBackground.svg";
import ReadPageImage from "../assets/ReadPageImage.svg";
import { useLocation,useNavigate } from "react-router-dom";

import SkipNext from "@mui/icons-material/SkipNext";
import SkipPrevious from "@mui/icons-material/SkipPrevious";
import {slugs} from '../utils/strings';
import DOMPurify from 'dompurify';
import '../css/readpage.css'


const ReadPage = (props) => {
  const [uri,setUri]=useState('');
  const location = useLocation();

  const {all_stories,index} = location.state || {};
  const [stories,setStories] = useState([]);
  const [storyIndex,setStoryIndex] = useState(0);
  const [story,setStory]=useState({});
  const [totalParts,setTotalParts] = useState(0);
  const scrollRef = useRef(null);
  const [storyNext,setstoryNext] = useState(false);
  const [storyPrevious,setstoryPrevious] = useState(false);

  const navigate = useNavigate();

  useEffect(()=>{
    setUri(localStorage.getItem('url'));
    window.scrollTo(0, 0);
  },[])

  const [currentScrollLevel, setCurrentScrollLevel] = useState([0]); // To track scroll level
 
  // Handle scrolling to calculate levels
  const handleScroll = (e) => {
    setstoryNext(false);
    setstoryPrevious(false);
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    
    const currentPart = Math.floor((scrollTop / (scrollHeight - clientHeight)) * totalParts);
    const contentHeight = scrollHeight;
    const visibleHeight = clientHeight; // 90vh equivalent
    console.log("scroll heights are:++++++++",contentHeight,visibleHeight);
    const total_parts = Math.ceil(contentHeight / visibleHeight);
    setTotalParts(total_parts);
    console.log("this part is working currecntly +++++++++++++",total_parts,storyIndex,stories.length,currentPart)
    if(currentPart >= 0.8*totalParts - 1  && storyIndex < stories.length -1){
      
      setstoryNext(true);
    }
  
    if(storyIndex > 0){
      setstoryPrevious(true);
    }
  
    // Update current scroll level based on the current part
    const newScrollLevel = Array.from({ length: currentPart + 1 }, (_, i) => i);
    setCurrentScrollLevel(newScrollLevel);
  };
  
  const story_page_slug = slugs.story_page_slug;
  const handleNext = () => {
    if (storyNext) {
     
      const next_story = stories[storyIndex+1];
      
      navigate(`/readpage/${props.skoob_id}/${next_story.id}-${story_page_slug}`,{
        state:{
            all_stories:stories,
            index:storyIndex+1
        }
    });
      // const currentTime = playerRef.current;
      // playerRef.current.audio.currentTime = currentTime + 10; // Skip forward by 10 seconds
    }
  };

 
  const handlePrevious = () => {
    if (storyPrevious) {
      const next_story = stories[storyIndex-1];
   
      navigate(`/readpage/${props.skoob_id}/${next_story.id}-${story_page_slug}`,{
        state:{
            all_stories:stories,
            index:storyIndex-1
        }
    });

    }
  };

  const cleanHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
  
    // Remove background styles
    doc.querySelectorAll("*").forEach((node) => {
      const style = node.getAttribute("style");
      const spans = node.querySelectorAll("span");
      spans.forEach((span) => {
        span.removeAttribute("style"); // Remove the <span> element
      });
      if (style && /background/i.test(style)) {
        node.setAttribute(
          "style",
          style
            .split(";")
            .filter((s) => !/background/i.test(s))
            .join(";")
        );
      }
    });
  
    // Remove paragraphs with only whitespace
    doc.querySelectorAll("p").forEach((node) => {
      node.style = 'margin-bottom:20px';
      if (!node.textContent.trim()) {
        node.remove();
      }
    });
  
    return doc.body.innerHTML;
  };


    const truncateText = (text, wordLimit) => {
      if (!text) return "";
      const words = text.split(" ");
      if (words.length > wordLimit) {
        return `${words.slice(0, wordLimit).join(" ")}...`;
      }
      return text;
    };
  

  const FetchStoriesDetails = async ()=>{
    const BACKEND_URL= localStorage.getItem('BACKEND_URL');
    const user_id= localStorage.getItem('unique_key');
    await axios.get(`${BACKEND_URL}/story/all/${props.skoob_id}`, { headers: {
      'Authorization': user_id
    } },)
        .then(response => {
          if(response.status === 200) return response.data.stories;
          else throw new Error('an error occured');
        }
      )
        .then(data => {

          setStories(data);
          data.forEach(myFunction)
          function myFunction(value, index, array){
            if (value.id === props.story_id){
              setStory(value)
             
              setStoryIndex(index);
              // if(index < array.length - 1) 
              //   {
              //     console.log("value has been set to true Next ++++++++++++++");
              //     setactiveNext(true)
                 
              //   }
              if(index > 0) 
                {
                  setstoryPrevious(true)
                  console.log("value has been set to true Previous ++++++++++++++")
                }
            }
          }
        })
      .catch(error => {
      })
  }

  useEffect(()=> {
    setstoryPrevious(false);
    setstoryNext(false);
    if(all_stories)
    { 
      setStories(all_stories);
      
      all_stories.forEach((value, index, array)=>{
            if(value.id === props.story_id){
              setStory(value);
              setStoryIndex(index);
              // if(index < array.length - 1) 
              //   {
              //     console.log("value has been set to true Next ++++++++++++++");
              //     setactiveNext(true)
              //   }
              if(index > 0) 
                {
                  // setactivePrevious(true)
                  setstoryPrevious(true)
                  console.log("value has been set to true Previous ++++++++++++++")
                }

            }
          });

          
      }
    else{
      FetchStoriesDetails();
    }
    // FetchStoriesDetails()

    if (scrollRef.current) {
      
      const contentHeight = scrollRef.current.scrollHeight;
      const visibleHeight = scrollRef.current.clientHeight; // 90vh equivalent
      setTotalParts(Math.ceil(contentHeight / visibleHeight));
      console.log("initial scroll ref current is:+++++++++++++++++++++",contentHeight,visibleHeight)
    }

  },[props.story_id])
  return (
    <>
    {story && <Box sx={{ backgroundColor: "#1E0E49",
      marginTop: {
        xs: "12vh",
        sm: "10vh",
        md: "10vh",
        lg: "10vh",
        xl: "10vh"
      }
      }}>
        <Box sx={{position:'relative',marginTop:-3}}>
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:{
            xs: "2.5vh",
            sm: "5vh",
            md: "5vh",
            lg: "10vh",
            xl: "10vh"
            },
            

          }}>
            <Box
                      component="img"
                      src={story?.book?.img_url}
                      alt="headerImg"
                      sx={{
                        width: {
                          xs: "50vw", // Small screens
                          sm: "40vw", // Medium screens
                          md: "40vw", // Larger screens
                          lg: "30vw", // Large screens
                          xl: "30vw", // Extra large screens
                        },
                        maxHeight: {
                          xs: "50vw", // Maintain maximum height for all screens
                          sm: "35vw",
                          md: "35vw",
                          lg: "25vw",
                          xl: "25vw",
                        },
                        borderRadius: "8px",
                        // aspectRatio: "1.78", // Maintain aspect ratio
                      }}
                    />
          </Box>
        </Box>

        <Box sx={{ margin: "10px",position:'relative',display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",textAlign:"center",gap:"5px" }}>
          {/* <img src={DualBackground} alt="headerImg" style={{ width: "100vw" }} /> */}
              <Typography
                variant="p"
                component="p" 
                sx={{
                  // position:'absolute',
                  // top:0,
                  // marginTop:'2vh',
                  color: "#fff",
                  fontSize: {
                    xs: 13,
                    sm: 14,
                    md: 14,
                    lg: 15,
                    xl: 16,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '700',
                  width: "90vw",
                  textAlign:'center',
                  // marginLeft:'5vw'
                }}
                >
                Ep {storyIndex+1}.  {story?.title}
              </Typography>

              
              <Box
              sx={{
                maxWidth:"90vw",
                minWidth:
                {
                  xs:"80vw",
                  sm:"75vw",
                  md:"70vw",
                  lg:"60vw",
                  xl:"50vw",

                },
                marginTop: "2px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                overflow:"hidden",
                gap:"5px"
              }}>
             <Typography
              variant="p"
              component="p"
              sx={{
                color: "grey",
                fontSize: {
                  xs: 11,
                  sm: 13,
                  md: 13,
                  lg: 15,
                  xl: 16,
                },
                fontFamily: "Open Sans",
                fontWeight: "400",
                marginLeft:"12%",
                width: {
                  xs:"40%",
                  sm:"40%",
                  md:"40%",
                  lg:"30%",
                  xl:"30%",

                }, // Adjust the width as needed
                textAlign: "right",
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Add an ellipsis for truncated text
              }}
            >
              {story?.book?.name || ""}
            </Typography>
              <Typography
                variant="p"
                component="p" 
                sx={{
                  marginRight:"12%",
                  display:"inline-block",
                  // position:'absolute',
                  // top:0,
                  // marginTop:'2vh',
                  color: "grey",
                  // WebkitLineClamp: '1', 
                  // WebkitBoxOrient: 'vertical',
                  // overflow: 'hidden',
                  // textOverflow: 'ellipsis', 
                  fontSize: {
                    xs: 11,
                    sm: 13,
                    md: 13,
                    lg: 15,
                    xl: 16,
                  },

                  fontFamily: "Open Sans",
                  fontWeight: '400',
                  // width: {
                  //   xs:"25%",
                  //   sm:"25%",
                  //   md:"25%",
                  //   lg:"25%",
                  //   xl:"25%",
                  // }, 
                  textAlign:'left',
                  whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Add an ellipsis for truncated text
                  // marginLeft:'5vw'
                }}
                
                >
                By {story?.book?.author} 
              </Typography>
              
              </Box>



              <Box
                  sx={{
                    marginTop: "20px",
                    padding: "5px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* Scrollable Box */}
                  <Box
                    ref={scrollRef}
                    onScroll={handleScroll}
                    sx={{
                      maxWidth: {
                        xs: "100%",
                        sm: "90%",
                        md: "80%",
                        lg: "60%",
                        xl: "50%",
                      },
                      height: "90vh",
                      overflowY: "scroll",
                      marginTop: "4px",
                      padding: "10px",
                      backgroundColor: "#2C0E5E",
                      border: "2px solid #2C0E5C",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography
                      
                      variant="p"
                      component="div" // Use div for HTML content
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: 15,
                          sm: 16,
                          md: 16,
                          lg: 17,
                          xl: 18,
                        },
                        fontFamily: "Open Sans",
                        fontWeight: "400",
                        lineHeight: 1.8,
                        textAlign: "left",
                        
                      }}
                     dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cleanHtml(story?.text)) }} 
                    />
                  </Box>

                  {/* Levels and Buttons */}
                  <Box
                    sx={{
                      position: "sticky",
                      bottom: "0px",
                      width: {
                        xs: "90vw",
                        sm: "90vw",
                        md: "80vw",
                        lg: "60vw",
                        xl: "60vw",
                      },
                      marginTop: "20px",
                      padding: "5px",
                      // backgroundColor: "#2C0E4D",
                      // border: "2px solid #2C0E3A",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection:"column",
                      gap: "10px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                 >
                    {/* Previous Button */}

                  <Box
                  sx={{
                    // border:"2px solid red",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}>
                    <Button
                    
                      variant="contained"
                      color="primary"
                      onClick={handlePrevious}
                      startIcon={<SkipPrevious />}
                      sx={{
                        display:storyPrevious ? 'visible':'none',
                        justifySelf : 'start',
                        backgroundColor: "#2C6E5E",
                        color: "#fff",
                        fontSize: {
                          xs: 10,
                          sm: 12,
                          md: 12,
                          lg: 14,
                          xl: 15,
                        },
                      }}
                    >
                      Previous
                    </Button>

                    <Box></Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      endIcon={<SkipNext />}
                      sx={{
                        display:storyNext ? 'visible':'none',
                        backgroundColor: "#2C6E5E",
                        color: "#fff",
                        justifySelf : 'flex-end',
                        fontSize: {
                          xs: 10,
                          sm: 12,
                          md: 12,
                          lg: 14,
                          xl: 14,
                        },
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                    

                    {/* Level Indicators */}
                    <Box
                        sx={{
                          width: "100%",
                          padding: "5px",
                          display: totalParts > 1 ? "flex" : 'none',
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#2C0E4D",
                          gap: "10px",
                        }}
                      >
                        {Array.from({ length: totalParts }).map((_, level) => (
                          <Box
                            key={level}
                            sx={{
                              width: `${100 / totalParts}%`, // Dynamically set width based on total parts
                              height: "3px",
                              backgroundColor: currentScrollLevel.includes(level) ? "blue" : "white",
                              borderRadius: "5px",
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                    {/* Next Button */}
                   
  
                </Box>
               </Box>


        <Box sx={{ marginTop: "1vh",position:'relative' }}>
          <img src={DualBackground} alt="headerImg" style={{ width: "100vw" }} />

          <Box sx={{width:'100vw',height:'0.5px',backgroundColor:'#FFFFFF',position:'absolute',top:0, marginTop:{
                  // xs: '10vh',
                  // sm: '13vh',
                  // md: '18vh',
                  // lg: '28vh',
                  // xl: '33vh',
                  xs: '0vh',
                  sm: '0vh',
                  md: '0vh',
                  lg: '0vh',
                  xl: '0vh',
                }}}></Box>
          <Typography
                variant="p"
                component="p" 
                sx={{
                  position:'absolute',
                  top:0,
                  marginTop:{
                  // xs: '12vh',
                  // sm: '15vh',
                  // md: '20vh',
                  // lg: '30vh',
                  // xl: '35vh',
                  xs: '2vh',
                  sm: '2vh',
                  md: '2vh',
                  lg: '3vh',
                  xl: '3vh',
                },
                  color: "#fff",
                  fontSize: {
                    xs: 18,
                    sm: 24,
                    md: 30,
                    lg: 36,
                    xl: 42,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '300',
                  width: "80vw",
                  textAlign:'center',
                  marginLeft:'10vw',
                  marginRight:'10vw'
                }}
                >
                Start Listening to story on Skoob App!
              </Typography>
          <Box sx={{position:'absolute',bottom:{
              xs: '12vh',
              sm: '15vh',
              md: '25vh',
              lg: '30vh',
              xl: '35vh',
            },width:'100vw',}}>
            <img src={ReadPageImage} alt="headerImg" style={{ width: "50vw",marginLeft:'25vw'}} />
          </Box>
          <a href={uri} style={{textDecoration:'none',}} target="_blank">
            <Box sx={{position:'absolute', display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',bottom:{
                xs: '30px',
                sm: '30px',
                md: '30px',
                lg: '30px',
                xl: '30px',
              },borderRadius:'6px',backgroundColor:'#FFFFFF',width:'90vw',marginLeft:'5vw',height:'45px',marginRight:'5vw',}}>
                <text
                    style={{ fontFamily: 'Open Sans',
                    fontWeight:'600',color:'#342067',fontSize: {
                      xs:14,
                      sm:16,
                      md:18,
                      lg:20,
                      xl:22,
                    },
                  }}
                  >Download Now
                </text>
            </Box>
          </a>
        </Box>
      </Box>}
    </>
  );
};

export default ReadPage;



{/* <p style="margin-top: 0pt; margin-bottom: 0pt;">
  <span style="font-size: 11pt; font-family: Arial, sans-serif;">
    Leonardo da Vinci dreamed of flying. He wrote that developing a machine capable of reaching the skies would be the one invention to gain him &ldquo;glory eternal.&rdquo; During the first years of the sixteenth century, Leonardo made careful observations of the Black Kites, larks, and other species common to the Italian countryside, jotting down his ideas in a private notebook that came to be called Codex on the Flight of Birds. Leonardo&rsquo;s designs for flying machines included a now-famous illustration of a primitive helicopter, as well as a flapping Icarus-style flier, but the most important pictures in the book may be a series of small birds sketched casually in the margins. They look like pigeons in flight, showing a variety of postures and depicting the birds with lines of air passing under and over each wing. Combined with his text on the &ldquo;thickness&rdquo; and &ldquo;thinness&rdquo; of air, these pictures make plain that Leonardo had begun to intuit the importance and function of airfoils.
    </span>
</p>

 <p>&nbsp;</p>

 <p style="margin-top: 0pt; margin-bottom: 0pt;">
  <span style="font-size: 11pt; font-family: Arial, sans-serif;">
    When air meets the front of a bird wing, it has a choice: take the low road, or take the high road. Both paths reach the other side, but they travel by different routes, at different rates, and through surprisingly different conditions. The angle and speed of the wing determine how much air is deflected downward, creating lift by increasing air pressure below the wing while reducing it above. It&rsquo;s Newton&rsquo;s third law at work: every action has an equal and opposite reaction. This effect is familiar to anyone who has held their arm out a car window and felt the wind force their hand up as they tilt it to cup the air. Shape matters, too. Seen in cross-section, a bird&rsquo;s wing has a curved top, a thick leading edge, and a long, tapering tail, like a comma tipped on its side and stretched thin. Airflow hugs that curved upper surface and exits behind the wing as downwash, further reducing the air pressure above and adding additional lift. This is also easy to test. Dangle a piece of paper in front of your lips and blow across the curved top surface&mdash;you will see the paper rise from below, pushed upward as your wind reduces the air pressure above the page.
    </span></p> 

<p>&nbsp;</p> 

<p style="margin-top: 0pt; margin-bottom: 0pt;">
  <span style="font-size: 11pt; font-family: Arial, sans-serif;">Leonardo was particularly well suited to understanding aerodynamics, having already studied the way water moved in streams, around obstacles, and through tubes of various widths. He was the first person to grasp that air and water moved by the same principles and is considered the father of the combined field of fluid dynamics. Though no one thinks of Leonardo as the father of ornithology, Rick Prum once made a study of his ornithological observations and noted how close he had been to the modern understanding of bird flight. If he knew what we know now, Prum observed, &ldquo;he would have cursed himself for not figuring it out!&rdquo; Had da Vinci published his findings, he might have spurred a great Renaissance interest in aerodynamics and advanced the goal of human flight by centuries.
  </span></p> 
  
  <p>&nbsp;</p> 
  
  <p style="margin-top: 0pt; margin-bottom: 0pt;">
    <span style="font-size: 11pt; font-family: Arial, sans-serif;">But the Codex languished unread in private hands, forcing other thinkers to rediscover Leonardo&rsquo;s ideas on their own. Detailed notions about birds and airfoils didn&rsquo;t emerge again until the late 1800s, with a pair of German brothers who &ldquo;devoted the greater part of our immature nature studies to watching our friend the stork.&rdquo; Otto and Gustav Lilienthal quickly progressed from observation to action, building their first flying machines while still in their teens. Though early attempts involved sewing together &ldquo;all the feathers which were obtainable in our town,&rdquo; they soon learned that the overall shape of the wing, the airfoil, was more important than replicating a bird exactly (the error that Daedalus made). They experimented with a wide range of airfoil shapes and recognized the importance of a curved upper surface. Their designs took on a distinctive look&mdash;heavily battened spreads of canvas surrounding a lone upright pilot, who controlled the craft by shifting his body weight from side to side. There was no motor. The Lilienthals made use of headwinds and updrafts, learning to soar with wings unmoving, just like the great white storks they sought to emulate. Known as the Glider King, Otto became the world&rsquo;s first truly famous aviator, completing more than two thousand successful flights&mdash;some longer than a thousand feet&mdash;before a crash in 1896 ended his life.
      </span>
  </p> <p>&nbsp;</p> 
  
  <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Orville and Wilbur Wright cited his exploits as their primary inspiration and based much of their early work on airfoils and equations from his book, Birdflight as the Basis of Aviation.&nbsp; Otto Lilienthal displays one of his many flying machines, 1894. In many ways, the Wright brothers began exactly where the Lilienthal brothers had left off. But while they learned much from their predecessors&rsquo; triumphs, they learned even more from their failures. Otto&rsquo;s fatal accident taught them that control in the air was ultimately more important than simply getting off the ground. The Wrights built a series of gliders that focused on steering and quietly began breaking all of the Lilienthal records for distance, speed, and duration. When it came time to add power to their flier, they had another forerunner to look to, one who also found inspiration in the natural airfoils of the birds.
        </span></p>
        
    <p>&nbsp;</p>
    
    <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">While the Wrights and Lilienthals achieved unquestioned technical success, it was up to a Frenchman to imbue aviation with a sense of style. Cl&eacute;ment Ader made his fortune in the telephone business, introducing and adapting Alexander Graham Bell&rsquo;s invention to an eager Parisian market. He then devoted himself to flying machines, investing the modern equivalent of millions of his own dollars into a series of elegant steam-powered aircraft. Like his contemporaries, Ader believed that the key to flight lay in the effortless soaring abilities of large birds. Instead of storks, however, he focused on vultures and once traveled to the hostile interior of Algeria, disguised as an Arab, to view African species in the wild. His designs looked more like bats in the end, but Ader&rsquo;s greatest contribution lay less in wing shape than in means of propulsion.
          </span></p> <p>&nbsp;</p>
          
    <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Though the Wrights and Lilienthals achieved unquestioned technical success, it was up to a Frenchman to imbue aviation with a sense of style. Cl&eacute;ment Ader made his fortune in the telephone business, introducing and adapting Alexander Graham Bell&rsquo;s invention to an eager Parisian market. He then devoted himself to flying machines, investing the modern equivalent of millions of his own dollars into a series of elegant steam-powered aircraft. Like his contemporaries, Ader believed that the key to flight lay in the effortless soaring abilities of large birds. Instead of storks, however, he focused on vultures and once traveled to the hostile interior of Algeria, disguised as an Arab, to view African species in the wild.
            </span></p> <p>&nbsp;</p> 
            
    
    <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">His designs looked more like bats in the end, but Ader&rsquo;s greatest contribution lay less in wing shape than in means of propulsion. Though it rose only eight inches into the air and couldn&rsquo;t be steered, Ader&rsquo;s &Eacute;ole claimed rights to the first self-propelled manned flight in human history in 1890, thirteen years before the Wright brothers at Kitty Hawk. The plane&rsquo;s small steam engine powered a graceful propeller made up of four giant feathers carved from wood. Ader had recognized that flight feathers, like wings, were perfect airfoils and that the same aerodynamic principles that provided lift could provide thrust if the vanes were mounted vertically and spun at great speed.
              </span></p> <p>&nbsp;</p> 
              
      <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">That separation of thrust and lift became a signature of virtually all future airplanes, and while the Wright brothers transformed propeller design into an exact science, Ader&rsquo;s whimsical feathery blades lived on in a burgeoning side industry. As late as the 1950s, model-airplane enthusiasts still built The Featherfly and other designs that called for plumed props. In the modern era of jet propulsion and in-flight DVD rentals, feather-shaped propellers seem decidedly quaint&mdash;something you might find in an old textbook or a dusty side room at the Air and Space Museum.
                </span></p> <p>&nbsp;</p> 
      
      <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">After the Wright brothers, conspicuous biomimicry declined in aviation, replaced by equations, wind-tunnel experiments, and, eventually, computer simulations. Few aeronautical engineers today spend much time outdoors stalking storks, hawks, or Algerian vultures. With the principles of flight and steering established, advances during the twentieth century basically focused on improving the components of the Wright Flyer&mdash;stronger engines, better airfoils, more responsive steering, larger wings and payloads. This is the evolution of technology: a large leap followed by a thousand tiny refinements. Within a few decades, aircraft designers had essentially perfected the form. The Cessna 172 is the world&rsquo;s most popular airplane, a reliable and efficient four-seater little changed from its original 1955 design. Boeing&rsquo;s 737 was introduced in the 1960s and remains the world&rsquo;s top-selling jetliner.
                  </span></p> <p>&nbsp;</p> 
                  
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">When innovation turns to impasse, technology often goes back to its roots for inspiration. The need for more efficient, maneuverable, quiet aircraft has brought biomimicry back into vogue, as top engineering labs reexamine the many nuances of feathers and bird flight. But where nineteenth-century aviators had only field glasses and a notebook, modern engineers can watch birds with high-speed cameras, laser range finders, digital modeling software, and other tools that delve beyond mimicry to reveal the fundamental wonders of feathered flight.
                    </span></p> <p>&nbsp;</p> 
        
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">A driving force behind the aviation industry&rsquo;s &ldquo;second look&rdquo; at bird flight lies in fuel efficiency. Spiking oil prices in 2008 contributed to a slew of airline bankruptcies, including such major carriers as Japan Airlines, Frontier, Skybus, and Aloha. Surviving companies are eager for ways to reduce fuel consumption in a world of ever-shrinking petroleum reserves. Lighter airplanes offer one option, but replacing aluminum with composites involves a complete redesign, with all new materials and manufacturing techniques. The first plane to go this route, Boeing&rsquo;s 787 Dreamliner, shows great promise but recently missed its seventh delivery deadline and remains mired in testing and development, three years overdue.
                      </span></p> <p>&nbsp;</p>
        
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Some engineers believe that quicker fixes lie in how a bird manipulates its wing feathers, a masterful display of what might best be called airflow management. Textbook diagrams of airfoils regularly leave out a critical detail: turbulence. Air passing around a wing never actually moves in the smooth lines of illustration&mdash;it swirls and eddies in complex patterns that change constantly with every subtlety of temperature, air pressure, wind speed, wing shape, and angle. There are layers of air dragged along with the wing, vortices tumbling above its surfaces, and spirals jetting off the tips. The process is far too complex for any drawing, but understanding it is critical to understanding drag, the natural resistance to a wing&rsquo;s forward motion. Any reduction in drag increases flight efficiency, offering immediate fuel savings to airlines.
                        </span></p> <p>&nbsp;</p> 
                        
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">And no one reduces drag better than birds. If you&rsquo;ve ever flown in a window seat, you may have admired the silvery shine of an airplane wing and watched its several flaps raise and lower at various times during the flight. It&rsquo;s a precise and beautifully designed instrument, but must look terribly crude to a bird, whose own wings can flap and flex, extend and contract, spread, narrow, tuck, and twist, responding instantly to ever-changing conditions. Taken together, the overlapping flight feathers create a single dynamic airfoil. But they can also move independently and are themselves shaped like airfoils, acting as individual winglets within the greater whole.
                          </span></p> <p>&nbsp;</p> 
                          
                          
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Vultures, eagles, and other soaring birds use small adjustments of their spread wing-tip &ldquo;fingers&rdquo; to manipulate air currents or change speed and orientation, and all birds utilize feather movements to instinctively alter the turbulence patterns around their wings. Slots can be opened or closed to direct air between primaries; covert feathers can be raised or lowered like tiny flags&mdash;the possibilities are endless. Teasing apart these intricacies challenges even the most advanced computer models, but engineers have already learned that adding artificial &ldquo;winglets&rdquo; to the tips of airplane wings can mimic the efficiency of a soaring raptor.
                            </span></p> <p>&nbsp;</p> 
                            
                            
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Passenger jets retrofitted with winglets have seen their fuel use drop by as much as 6 percent, a substantial savings considering that a fully loaded 747 can burn through a gallon or more every second. Now in common use, these small vertical fins have saved the airline industry billions of dollars in fuel costs. A potentially more lucrative lesson can be summarized in one unexpected word:
                              </span><span style="font-size: 11.5pt; font-family: Arial, sans-serif; background-color: #ffffff;">fuzziness. Photographs of birds in flight often show splayed and uneven flight feathers, or coverts lifted at sharp angles above the wing&mdash;like Ken Franklin&rsquo;s amazing pictures of a falcon catching shorebirds.&nbsp;
                                </span></p> <p>&nbsp;</p> 
                                
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11.5pt; font-family: Arial, sans-serif; background-color: #ffffff;">Engineers now believe this intentional &ldquo;roughening&rdquo; of the wing surface may substantially reduce turbulence and drag. A fully feathered jetliner is probably out of the question, but simulations suggest that just covering the wings with simple bristles could improve flight efficiency by as much as 15 percent. Typically, air passing over the surface of a wing (or any airfoil) breaks apart into tiny eddies that pull away from the surface, a form of turbulence that results in additional drag and pockets of dead air directly behind the wing. When bicyclists tuck into the slipstream behind a lead rider, they&rsquo;re taking advantage of this principle&mdash;saving energy by riding in a low-pressure, low-turbulence position. It&rsquo;s counterintuitive, but rough surfaces can help reduce drag by manipulating the formation of eddies and keeping them close to the airfoil surface.
                                  </span></p> <p>&nbsp;</p>
        
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11.5pt; font-family: Arial, sans-serif; background-color: #ffffff;">Years from now, you may peer out an airplane window and see a fuzzy wing, each bristle the manufacturer&rsquo;s best approximation of a feather. Managing the airflow around wings can have the added benefit of substantially reducing aircraft noise, an important consideration for anyone living on a busy flight path. When owls pass overhead, their eerily silent wing strokes seem otherworldly, and they&rsquo;ve long been linked to mythologies of the spirit realm. But there is nothing supernatural about an owl&rsquo;s flight&mdash;their wings simply part the air in a different way. Owl feathers feature barb extensions on the leading and trailing edges that reduce turbulence over individual feathers as well as the entire wing, increasing efficiency but, most important, muffling the sound of their approach. This stealth gives them a key advantage over the wary and sharp-eared mammals and birds they prey upon. (In a tidy and satisfying piece of evolution, the Scops Fishing Owl lacks these feather modifications&mdash;there&rsquo;s no need to fly silently when your prey is underwater and can&rsquo;t hear you coming!) The promise of quieter airplanes makes owl feathers an intriguing model for commercial carriers, whose takeoffs and landings at urban airports are dictated in part by sound levels.</span></p> */}