import {React,useEffect,useState,useRef,useContext} from 'react';
import {
    Box,
    Typography,
} from '@mui/material'

import SkoobReview1 from '../assets/Review1.svg'
import SkoobReview2 from '../assets/Review2.svg'
import SkoobReview3 from '../assets/Review3.svg'
import SkoobReview4 from '../assets/Review4.svg'

import ReadytobeSmart from '../assets/ReadytobeSmart.svg'

import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web'
import ReactGA from "react-ga4";
import { UriContext } from '../pages/Main';

const Testimonials = () => {

        const reviewContainerRef = useRef(null);
        const reviewScrollRef = useRef(0);
        // const [uri,setUri]=useState('');
        
        const uri = useContext(UriContext);

        const handleClick = (event) => {
                try {
                  ReactGA.event({
                    category: "download_clicks",
                    action: "Download_BottomBar",
                    label: 'ready_to_smart_download_button',
                  });
              
                } catch (error) {
                //   console.error("error:", error);
                }
              };
        
        // useEffect(()=>{
        //     setUri(localStorage.getItem('url'));
        //     },[])
        
        const reviewsList = [SkoobReview1,SkoobReview2,SkoobReview3,SkoobReview4];
        
        const scrollToreviewIndex = (index) => {
            if (!reviewContainerRef.current || reviewsList.length === 0) return;
            const screenWidth = Math.min(window.innerWidth,600);
            
            const scrollAmount = index * screenWidth; // Width of each item + margin
           
            reviewContainerRef.current.scrollTo({
              left: scrollAmount,
              behavior: "smooth",
            });
          };
    
        useEffect(() => {
            if (!reviewContainerRef.current) return; // Ensure ref is assigned
            if (reviewsList.length === 0) return;
    
                const interval = setInterval(() => {
                    
                      const nextIndex = (reviewScrollRef.current + 1) % reviewsList.length;
                      scrollToreviewIndex(nextIndex);
                      reviewScrollRef.current = nextIndex;
                    
                  }, 20000);
        
            return () => clearInterval(interval); // Cleanup interval on unmount
          }, [reviewsList]);
    
    
        const colorReviewDots = (activeIndex)=> {
            const dots = document.querySelectorAll('.review-dot');
            dots.forEach((dot, index) => {
                dot.style.backgroundColor = index === activeIndex ? '#fff' : '#888';
            });
        }
        
        const handleReviewScroll = () => {
            if (!reviewContainerRef.current) return;
            const scrollLeft = reviewContainerRef.current.scrollLeft;
            const itemwidth = Math.min(window.innerWidth,600);
            
            const index = Math.round(scrollLeft / itemwidth); // Adjusted to `round` for better accuracy
            
            reviewScrollRef.current = index;
            colorReviewDots(index)
            
        // setActiveIndex(index);
        };


    const [ref, inView] = useInView({
      triggerOnce: true,
    });
    const springProps = useSpring({
      from: { opacity: 0, transform: 'translateY(5%)' },
      to: async (next) => {
        await next({ opacity: 1, transform: 'translateY(0%)' });
      },
      config: { duration: 500 },
      immediate: !inView,
      reset: true,
    });
    return (
        <animated.div id="WhenToSkoob" style={{
            ...springProps,
        }} >
        <Box ref={ref} sx={{ color: '#fff',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',paddingBottom:'60px' }}>

               <Box
                        sx={{
                            width: '100%',
                            maxWidth:'600px',
                            // border:'2px solid red',
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'start',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '80px',
                            paddingTop:'10px',
                        }}
                            >

                           <Box
                                style={{
                                position: 'relative',
                                zIndex: 1, 
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent:'center',
                                
                                gap:'8px',
                                overflow:'hidden',
                                paddingInline:'20px',
                                marginLeft: '0px',
                                }}
                            >
                                {/* Podcasts Heading */}
                                <Typography
                                variant="h4"
                                component="h4"
                                sx={{
                                    color: '#fff',
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontWeight: '600',
                                    lineHeight: '36px',
                                    letterSpacing: '3%',
                                    fontSize: '24px',
                                    textAlign:'center'
                                }}
                                >
                                Learn Smarter with Thousands of Skoob Users
                                </Typography>

                             
                                <Typography
                                variant="h4"
                                component="p"
                                sx={{
                                    color: '#fff',
                                    lineHeight: '24px',
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontWeight: '500',
                                    letterSpacing: '5%',
                                    fontSize: '14px',
                                }}
                                >
                                See why users love Skoob!
                                </Typography>
                            </Box>
                            
                        

                            <Box
                                style={{
                                    zIndex: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    gap: '8px',
                                    maxWidth: '600px',
                                    width: '100vw',
                                    marginLeft: '0px',
                                }}
                                >
                                    {/* Scrollable Container */}
                                    <Box
                                        ref={reviewContainerRef}
                                        className="hide-scrollbar"
                                        sx={{
                                        width: '100%',
                                        overflowX: 'auto',
                                        whiteSpace: 'nowrap',
                                        scrollBehavior: 'smooth', 
                                        scrollbarWidth: "none", 
                                        "&::-webkit-scrollbar": { display: "none" },
                                        }}
                                        onScroll={handleReviewScroll} 
                                    >
                                       
                                        {reviewsList.map((review, index) => (
                                        <Box
                                            key={`review${index}`}
                                            sx={{
                                            display: 'inline-block',
                                            width:'100vw',
                                            maxWidth:'600px',
                                            scrollSnapAlign:'center',
                                            textAlign: 'center',
                                            }}
                                        >
                                         <Box sx={{width:{xs:'90%',sm:'85%',md:'80%'},display:'inline-block'}}>
                                            <img
                                            src={review}
                                            alt={'reviews'}
                                            style={{
                                                width: '100%', 
                                                height: 'auto', 
                                                margin:'0 auto',
                                                borderRadius: '16px', 
                                            }}
                                            />
                                          </Box>
                                        </Box>
                                        ))}
                                    </Box>

                                    {/* Dots for Scroll Indicators */}
                                    <Box
                                        sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '4px',
                                        marginTop: '10px',
                                        }}
                                    >
                                        {reviewsList.map((_, index) => (
                                        <Box
                                            className="review-dot"
                                            key={index}
                                            onClick={() => scrollToreviewIndex(index)} // Scroll to item on dot click
                                            sx={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            
                                            backgroundColor: reviewScrollRef.current === index ? '#fff' : '#888',
                                            cursor: 'pointer',
                                            transition: 'background-color 0.3s',
                                            }}
                                        />
                                        ))}
                                    </Box>
                                
                                </Box>

                
                        </Box>

                    
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: { xs: '600px' },
                                height: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: {xs:'40px',sm:'60px'},
                                position: 'relative',
                            }}
                        >
                            {/* Image Container */}
                            <Box
                                sx={{
                                    width: {xs:'90%',sm:'85%',md:'80%'},
                                    height: 'auto',
                                    borderRadius: '16px',
                                    overflow: 'hidden',
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={ReadytobeSmart}
                                    alt="listen skoob anytime"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        borderRadius: '16px',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>

                            {/* Download Button */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: { xs: '25%', sm: '25%', md: '25%' }, // Adjust position relative to image
                                    left: { xs: '31%', sm: '28%', md: '30%' },
                                    transform: 'translateX(-50%)', // Center horizontally
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        borderRadius: '42px',
                                        display: 'flex',
                                        paddingX: '24px',
                                        paddingY: '14px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxShadow: '0px 2px 15px -5px #FFFFFF',
                                        backgroundColor: '#FFFFFF',
                                        width: '168px',
                                        height: '46px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <a href={uri} onClick={handleClick} style={{ textDecoration: 'none',backgroundColor: '#FFFFFF' }} target="_blank" rel="noopener noreferrer">
                                        <span
                                            style={{
                                                fontFamily: 'Open Sans',
                                                fontWeight: '700',
                                                color: 'black',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                letterSpacing: '1%',
                                            }}
                                        >
                                            Download App
                                        </span>
                                    </a>
                                </Box>
                            </Box>
                        </Box>


        </Box>
    </animated.div>
    )
}

export default Testimonials;