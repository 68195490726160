import React,{createContext} from 'react';
import About from '../components/updatedAbout';
// import Features from '../components/Features';
// import Home from '../components/Home';
import WhySkoob from '../components/updatedWhy';
// import HowItWorks from '../components/HowItWorks';
// import Footer from '../components/Footer';
// import Navbar from '../components/Navbar';
import WhenToSkoob from '../components/WhenToSkoob';
// import HomeUser from './HomeUser';
import Testimonials from '../components/Testimonials';
import UpdatedHome from '../components/updatedHome';
import UpdatedNavbar from '../components/updatedNavbar';
import UpdatedFooter from '../components/updatedFooter';

export const UriContext = createContext();

const Main = ({uri}) => {
  return (
    <div
      style={{ backgroundColor: '#000000',width:'100vw'}}
    > 
      <div style={{ backgroundColor: '#181819',maxWidth:'600px',width:'100vw',position:'relative',left:'50vw',top:0,transform:'translateX(-50%)'}}>
        <UriContext.Provider value={uri}> 
          <UpdatedNavbar />    
          <UpdatedHome />
          {/* <HomeUser /> */}
          <About />
          {/* <Features /> */}
          <WhySkoob />
          <WhenToSkoob />
          <Testimonials />
          {/* <HowItWorks /> */}
          <UpdatedFooter/>
        </UriContext.Provider>
      </div>

    </div>
  )
}

export default Main