import React from "react";
import { Box, Typography } from "@mui/material";
const Privacy = () => {
  return (
    <Box
      sx={{ backgroundColor: "#181819",maxWidth:'600px', width: "100vw",paddingBottom: 50,position:'absolute',left:'50vw',top:0,transform:'translateX(-50%)'}}
    >
      <Typography
        sx={{
          fontSize: {
            xs: 28,
            sm: 32,
            md: 36,
            lg: 40,
            xl: 44,
          },
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          paddingTop: 5,
          textAlign:'center'
        }}
      >
        Privacy Policy
      </Typography>
      <Typography
        sx={{
          fontSize: 20,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          marginTop: 5,
          textAlign:'center'

        }}
      >
        1. Information We Collect
      </Typography>
      <Box sx={{   marginTop: 2,marginLeft:5,marginRight:5, }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          1. Personal Information: This includes your name, email address,
          postal address, phone number, and other similar identifiers.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          2. Usage Data: We automatically collect information about your
          interactions with our Services, including IP address, browser type,
          operating system, referring URLs, pages viewed, and access times.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          3. Cookies and Tracking Technologies: We use cookies and similar
          tracking technologies to collect information about your browsing
          behavior and preferences. You can control cookies through your browser
          settings.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign:'center'    
        }}
      >
        2. How We Use Your Information
      </Typography>
      <Box sx={{   marginTop: 2,marginLeft:5,marginRight:5,  }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          1. Providing and improving our Services.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          2. Responding to your inquiries and providing customer support.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          3. Sending you updates, newsletters, and promotional materials.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          4. Analyzing usage patterns to enhance user experience.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          5. Protecting against fraud and maintaining the security of our
          Services.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign:'center'

          
        }}
      >
        3. How We Share Your Information
      </Typography>
      <Box sx={{   marginTop: 2 ,marginLeft:5,marginRight:5, }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          1. Service Providers: We may share your information with third-party
          service providers who assist us in delivering our Services (e.g.,
          hosting, analytics, payment processing).
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          2. Legal Compliance: We may disclose your information when required by
          law, such as in response to a subpoena or other legal process.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          3. Business Transfers: If Skoob undergoes a merger, acquisition, or
          sale of assets, your information may be transferred as part of the
          transaction.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          4. Consent: We may share your information with your consent.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign:'center'

          
        }}
      >
        4. Your Choices
      </Typography>
      <Box sx={{   marginTop: 2,marginLeft:5,marginRight:5,  }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          1. Access and Update: You can access and update your personal
          information by logging into your account or contacting us.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          2. Marketing Communications: You can opt out of receiving marketing
          communications from us by following the unsubscribe instructions
          provided in the email or contacting us.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            marginLeft: 3,
            textAlign:'center'

          }}
        >
          3. Cookies: You can control cookies through your browser settings.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign:'center'

          
        }}
      >
        5. Data Security
      </Typography>
      <Box sx={{   marginTop: 2 ,marginLeft:5,marginRight:5, }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          1. We implement security measures to protect your information from
          unauthorized access, disclosure, alteration, or destruction. However,
          no method of transmission over the internet or electronic storage is
          completely secure, and we cannot guarantee absolute security.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign:'center'
          
        }}
      >
        6. Children's Privacy
      </Typography>
      <Box sx={{   marginTop: 2,marginLeft:5,marginRight:5,  }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          1. Our Services are not intended for individuals under the age of 13.
          We do not knowingly collect personal information from children under
          13. If you believe we have collected information from a child under
          13, please contact us.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign:'center'

          
        }}
      >
        7. Changes to this Privacy Policy
      </Typography>
      <Box sx={{   marginTop: 2 ,marginLeft:5,marginRight:5, }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          1. We may update this Privacy Policy from time to time. The date at
          the top of this policy indicates when it was last revised. Changes are
          effective upon posting, so please review this policy regularly.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign:'center'

          
        }}
      >
        8. Contact Us
      </Typography>
      <Box sx={{   marginTop: 2,marginLeft:5,marginRight:5,  }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign:'center'

          }}
        >
          1. If you have any questions, concerns, or requests regarding this
          Privacy Policy, please contact us at info@skoob.ai.
        </Typography>
      </Box>
      <Typography
        sx={{
          marginLeft:5,marginRight:5,
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign:'center'
        }}
      >
        Thank you for choosing Skoob. Your privacy is important to us.
      </Typography>
    </Box>
  );
};

export default Privacy;
