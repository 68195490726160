import React, { useEffect ,useState,useRef} from "react";
import axios from 'axios';
import {
  Box,
  Typography,
} from "@mui/material";
import DualBackground from "../assets/DualBackground.svg";
import ReadPageImage from "../assets/ReadPageImage.svg";

import ReactH5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css'; 
import { useLocation,useNavigate } from "react-router-dom";
import { SkipNext, SkipPrevious } from '@mui/icons-material';
import { RHAP_UI } from "react-h5-audio-player";

import {slugs} from '../utils/strings';
import '../css/customAudioPlayer.css';

const TrackPlayer = (props) => {
  const [uri,setUri]=useState('');
  const location = useLocation();
  const {all_stories,index} = location.state || {};
  const [stories,setStories] = useState([]);
  const [storyIndex,setStoryIndex] = useState(0);
  const [activeNext,setactiveNext] = useState(false);
  const [activePrevious,setactivePrevious] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [audio_url,setAudioUrl] = useState('');
  const [story,setStory]=useState({});
  const navigate = useNavigate();

  const story_page_slug = slugs.story_page_slug;
  
  const playerRef = useRef(null); 

  const handleNextClick = () => {
    if (playerRef.current && activeNext) {
      
      const next_story = stories[storyIndex+1];
      console.log("encoded string is:++ds=====",btoa(next_story.id,'base64'));
      navigate(`/trackplayer/${props.skoob_id}/${next_story.id}-${story_page_slug}`,{
        state:{
            all_stories:stories,
            index:storyIndex+1
        }
    });
      // const currentTime = playerRef.current;
      // playerRef.current.audio.currentTime = currentTime + 10; // Skip forward by 10 seconds
    }
  };
 
  const handleBackClick = () => {
    if (playerRef.current  && activePrevious) {
      const next_story = stories[storyIndex-1];
   
      navigate(`/trackplayer/${props.skoob_id}/${next_story.id}-${story_page_slug}`,{
        state:{
            all_stories:stories,
            index:storyIndex-1
        }
    });

      // const currentTime = playerRef.current.audio.currentTime;
      // playerRef.current.audio.currentTime = Math.max(currentTime - 10, 0); // Skip backward by 10 seconds, but not less than 0
    }
  };

  useEffect(()=>{
    console.log("started trackplayer ++++++++++++ \n\n ++++++========")
    setUri(localStorage.getItem('url'));
    window.scrollTo(0, 0);

    const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
  },[])


  const FetchStoriesDetails = async ()=>{
    const BACKEND_URL= localStorage.getItem('BACKEND_URL');
    const user_id= localStorage.getItem('unique_key');
    try {
      const response = await axios.get(`${BACKEND_URL}/story/all/${props.skoob_id}`, {
        headers: {
          'Authorization': user_id, // Ensure this is correct, typically a token
        },
      });
    
      if (response.status === 200) { // Check for HTTP 200 status
        const data = response.data.stories; // Axios directly provides parsed JSON
      
        if (data) {
          setStories(data) // Spread and push stories into the array
        }
       
          data.forEach(myFunction)
          function myFunction(value, index, array){
            if(value.id === props.story_id){
              setStory(value);

              setStoryIndex(index);
              if(index < array.length - 1) 
                {
                  console.log("value has been set to true Next ++++++++++++++");
                  setactiveNext(true)
                }
              if(index > 0) 
                {
                  setactivePrevious(true)
                  console.log("value has been set to true Previous ++++++++++++++")
                }
              

            }
          }
        }
      }
      catch(error){
        console.log("and error occured:",error)
      }
  }

  const getStoryUrl =  async () => {

    const BACKEND_URL= localStorage.getItem('BACKEND_URL');
    const user_id= localStorage.getItem('unique_key');
    try {
      const response = await axios.get(`${BACKEND_URL}/story/${props.story_id}/audio_url`, {
        headers: {
          'Authorization': user_id, // Ensure this is correct, typically a token
        },
      });
    
      if (response.status === 200) { // Check for HTTP 200 status
        const data = response.data.audio_url; // Axios directly provides parsed JSON
      
        if (data) {
          setAudioUrl(data) // Spread and push stories into the array
        }
    
        }
      }
      catch(error){
        console.log("and error occured:",error)
      }


  }

  useEffect(()=> {
    setactiveNext(false);
    setactivePrevious(false);
    getStoryUrl();
    if(all_stories)
    { 
      setStories(all_stories)
      all_stories.forEach((value, index, array)=>{
            if(value.id === props.story_id){
              setStory(value);
              setStoryIndex(index);
              if(index < array.length - 1) 
                {
                 
                  setactiveNext(true)
                }
              if(index > 0) 
                {
                  setactivePrevious(true)
                
                }
             
            }
          });

          
      }
      else{
        FetchStoriesDetails();
      }
    
    // FetchStoriesDetails()
  },[props.story_id])

  return (
    <>
    {story.length === 0 && <Box sx={{position:'relative',marginTop:-3}}>
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:{
            xs: "2.5vh",
            sm: "5vh",
            md: "5vh",
            lg: "10vh",
            xl: "10vh"
            },
            

          }}>
           Loading............
          </Box>
        </Box>}


    {story && <Box sx={{ backgroundColor: "#1E0E49",
      marginTop: {
        xs: "12vh",
        sm: "10vh",
        md: "10vh",
        lg: "10vh",
        xl: "10vh"
      }
      }}>
        <Box sx={{position:'relative',marginTop:-3}}>
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:{
            xs: "2.5vh",
            sm: "5vh",
            md: "5vh",
            lg: "10vh",
            xl: "10vh"
            },
            

          }}>
            <Box
          component="img"
          src={story?.book?.img_url}
          alt="headerImg"
          sx={{
            width: {
              xs: "50vw", // Small screens
              sm: "40vw", // Medium screens
              md: "40vw", // Larger screens
              lg: "30vw", // Large screens
              xl: "30vw", // Extra large screens
            },
            maxHeight: {
              xs: "50vw", // Maintain maximum height for all screens
              sm: "35vw",
              md: "35vw",
              lg: "25vw",
              xl: "25vw",
            },
            borderRadius: "8px",
            // aspectRatio: "1.78", // Maintain aspect ratio
          }}
        />
          </Box>
        </Box>

        <Box sx={{ margin: "10px",position:'relative',display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",textAlign:"center",gap:"5px" }}>
          {/* <img src={DualBackground} alt="headerImg" style={{ width: "100vw" }} /> */}
              <Typography
                variant="p"
                component="p" 
                sx={{
                  // position:'absolute',
                  // top:0,
                  // marginTop:'2vh',
                  
                  color: "#fff",
                  fontSize: {
                    xs: 13,
                    sm: 14,
                    md: 15,
                    lg: 15,
                    xl: 17,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '600',
                  width: "90vw",
                  textAlign:'center',
                  // marginLeft:'5vw'
                }}
                >
                Ep {storyIndex+1}.  {story?.title}
              </Typography>

              
              <Box
              sx={{
               
                maxWidth:"90vw",
                minWidth:
                {
                  xs:"80vw",
                  sm:"75vw",
                  md:"70vw",
                  lg:"60vw",
                  xl:"50vw",

                },
                marginTop: "2px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                overflow:"hidden",
                gap:"5px"
              }}>
             <Typography
              variant="p"
              component="p"
              sx={{
                color: "grey",
                fontSize: {
                  xs: 11,
                  sm: 13,
                  md: 13,
                  lg: 15,
                  xl: 16,
                },
                fontFamily: "Open Sans",
                fontWeight: "400",
                marginLeft:"12%",
                width: {
                  xs:"40%",
                  sm:"40%",
                  md:"40%",
                  lg:"30%",
                  xl:"30%",

                }, // Adjust the width as needed
                textAlign: "right",
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Add an ellipsis for truncated text
              }}
            >
              {story?.book?.name || ""}
            </Typography>
              <Typography
                variant="p"
                component="p" 
                sx={{
                  marginRight:"12%",
                  display:"inline-block",
                  // position:'absolute',
                  // top:0,
                  // marginTop:'2vh',
                  color: "grey",
                  // WebkitLineClamp: '1', 
                  // WebkitBoxOrient: 'vertical',
                  // overflow: 'hidden',
                  // textOverflow: 'ellipsis', 
                  fontSize: {
                    xs: 11,
                    sm: 13,
                    md: 13,
                    lg: 15,
                    xl: 16,
                  },

                  fontFamily: "Open Sans",
                  fontWeight: '400',
                  // width: {
                  //   xs:"25%",
                  //   sm:"25%",
                  //   md:"25%",
                  //   lg:"25%",
                  //   xl:"25%",
                  // }, 
                  textAlign:'left',
                  whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Add an ellipsis for truncated text
                  // marginLeft:'5vw'
                }}
                
                >
                By {story?.book?.author} 
              </Typography>
              
              </Box>

              <Box
            sx={{
              width: "100%",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
        <ReactH5AudioPlayer
            ref={playerRef} // Attach ref to control the player
            src={audio_url} // Audio URL
            showSkipControls={true}
            onPlay={() => console.log("Playing...")}
            onClickPrevious={handleBackClick} // Custom logic for back button
            onClickNext={handleNextClick} // Custom logic for next button
            progressJumpSteps={{
              backward: 10000, // 5 seconds rewind
              forward: 10000,  // 5 seconds forward
            }}
            customIcons={{
              previous: (
                <SkipPrevious
                  style={{ color: activePrevious ? '#fff' : 'grey', fontSize: "30px" }}
                  onClick={handleBackClick}
                />
              ),
              next: (
                <SkipNext
                  style={{ color: activeNext ? '#fff' : 'grey', fontSize: "30px" }}
                  onClick={handleNextClick}
                />
              ),
            }}
            customAdditionalControls={[]} // Hide extra controls
            customVolumeControls={[]} // Hide volume controls
            autoPlayAfterSrcChange={false}
            style={{
              width: "90%",
              borderRadius: "8px",
              backgroundColor: "#2C0E5E",
              color:"#fff"
            }}
            customProgressBarSection={[
              RHAP_UI.CURRENT_TIME,
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.DURATION,
            ]}
            customTimeStyle={{
              color: "#fff", // Time text color
              fontSize: "14px",
            }}
      />
          </Box>

        </Box>

        <Box sx={{ marginTop: "1vh",position:'relative' }}>
          <img src={DualBackground} alt="headerImg" style={{ width: "100vw" }} />

          <Box sx={{width:'100vw',height:'0.5px',backgroundColor:'#FFFFFF',position:'absolute',top:0, marginTop:{
                  // xs: '10vh',
                  // sm: '13vh',
                  // md: '18vh',
                  // lg: '28vh',
                  // xl: '33vh',
                  xs: '0vh',
                  sm: '0vh',
                  md: '0vh',
                  lg: '0vh',
                  xl: '0vh',
                }}}></Box>
          <Typography
                variant="p"
                component="p" 
                sx={{
                  position:'absolute',
                  top:0,
                  marginTop:{
                  // xs: '12vh',
                  // sm: '15vh',
                  // md: '20vh',
                  // lg: '30vh',
                  // xl: '35vh',
                  xs: '2vh',
                  sm: '2vh',
                  md: '2vh',
                  lg: '3vh',
                  xl: '3vh',
                },
                  color: "#fff",
                  fontSize: {
                    xs: 18,
                    sm: 24,
                    md: 30,
                    lg: 36,
                    xl: 42,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '300',
                  width: "80vw",
                  textAlign:'center',
                  marginLeft:'10vw',
                  marginRight:'10vw'
                }}
                >
                Start Listening to story on Skoob App!
              </Typography>
          <Box sx={{position:'absolute',bottom:{
              xs: '12vh',
              sm: '15vh',
              md: '25vh',
              lg: '30vh',
              xl: '35vh',
            },width:'100vw',}}>
            <img src={ReadPageImage} alt="headerImg" style={{ width: "50vw",marginLeft:'25vw'}} />
          </Box>
          <a href={uri} style={{textDecoration:'none',}} target="_blank">
            <Box sx={{position:'absolute', display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',bottom:{
                xs: '30px',
                sm: '30px',
                md: '30px',
                lg: '30px',
                xl: '30px',
              },borderRadius:'6px',backgroundColor:'#FFFFFF',width:'90vw',marginLeft:'5vw',height:'45px',marginRight:'5vw',}}>
                <text
                    style={{ fontFamily: 'Open Sans',
                    fontWeight:'600',color:'#342067',fontSize: {
                      xs:14,
                      sm:16,
                      md:18,
                      lg:20,
                      xl:22,
                    },
                  }}
                  >Download Now
                </text>
            </Box>
          </a>
        </Box>
      </Box>}
    </>
  );
};

export default TrackPlayer;
