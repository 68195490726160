import React, {useEffect,useState}from  'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import DeepLinkLanding from './pages/DeepLinkLanding';
import UnsubscribeEmails from './pages/Unsubscribe';
import HomeUser from './pages/HomeUser';
import ReactGA from "react-ga4";

function App() {
  // localStorage.setItem('unique_key', "hhlo4pwh:bsiphc60");

  const [url,setUrl] = useState('');
  const getMobileOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "Android"
    }
    else if (/iPad|iPhone|iPod/.test(ua)){
      return "iOS"
    }
    return "Desktop"
  }

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
    ReactGA.send({hitType:'pageview',page:window.location.pathname});

  },[]);

  useEffect(()=>{
    const systemos = getMobileOS();
    let appUrl = systemos === "Android" 
                              ? 'https://play.google.com/store/apps/details?id=com.skoobai'
                              : 'https://apps.apple.com/us/app/skoob-ai/id6473138854'
    
    setUrl(appUrl)
    localStorage.setItem('url',appUrl);
    if(localStorage.getItem('BACKEND_URL')){
      localStorage.removeItem('BACKEND_URL');
      localStorage.removeItem('unique_key');
    }
    // if(systemos === "Android"){
    //   localStorage.setItem('url','https://play.google.com/store/apps/details?id=com.skoobai');
    // }
    // else if(systemos === "iOS"){
    //   localStorage.setItem('url','https://apps.apple.com/us/app/skoob-ai/id6473138854');
    // }
    // else{
    //   localStorage.setItem('url','https://apps.apple.com/us/app/skoob-ai/id6473138854');
    // }
  },[]);

  // useEffect(() => {
  //   const disableRightClick = (event) => {
  //     event.preventDefault();
  //   };

  //   // document.addEventListener('contextmenu', disableRightClick);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     document.removeEventListener('contextmenu', disableRightClick);
  //   };
  // }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main uri={url} />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/unsubscibe/:uid' element={<UnsubscribeEmails />} />
          <Route path="/trivia/:id" element={<DeepLinkLanding/>} />
          <Route path='/skoobdetails/:id' element={<DeepLinkLanding/>} />
          <Route path="/trackplayer/:skoob_id/:story_id" element={<DeepLinkLanding/>} />
          <Route path="/readpage/:skoob_id/:story_id" element={<DeepLinkLanding/>} />
          <Route path="/HomeUser" element={<HomeUser/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
