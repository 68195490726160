import React,{ useState,useEffect,useContext } from 'react'
import { Box, styled, Typography,keyframes } from "@mui/material";
import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

import skoobHomemicLogo from '../assets/skoobHomemicLogo2.svg'
import starredBackground from '../assets/starredBackground.svg'
import { useSpring, animated } from '@react-spring/web'
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import '../css/hidescrollbar.css'
import ReactGA from "react-ga4";
import { UriContext } from '../pages/Main';

const UpdatedHome = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
      });

    // const [uri,setUri]=useState('');
    const uri = useContext(UriContext);

    const handleClick = (event) => {
        try {
           
          ReactGA.event({
            category: "download_clicks",
            action: "Download_Home",
            label: 'home_download_button',
          });
      
        } catch (error) {
        //   console.error("error:", error);
        }
      };
    

    // useEffect(()=>{
    //     setUri(localStorage.getItem('url'));
    //   },[])
    
    const navigate = useNavigate();


      const springProps = useSpring({
        from: { opacity: 0, transform: 'translateY(15%)' },
        to: async (next) => {
          await next({ opacity: 1, transform: 'translateY(0%)' });
        },
        config: { duration: 400 },
        immediate: !inView,
        reset: true,
      });
    
    

    const CustomBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(1),
        backgroundColor: '#181819',
        textAlign:'center',
        zIndex:500,
        minHeight:'40vh',
        backgroundSize:'cover' ,
        // [theme.breakpoints.down('md')]: {
        //     flexDirection: 'column',
        //     alignItems: 'center',
        //     textAlign: 'center',
        // },
        '&::before': {
        content: '""', 
        position: 'absolute', 
        top: 0,
        left: 0,
        width: '100%',
        height: '55vh', 
        backgroundImage: `url(${starredBackground})`,
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        zIndex: 2000, 
        pointerEvents: 'none',
    },
    }));
    return (
        <Box
        id="Home"
        ref={ref}
        >
            
            <CustomBox component='body'>
             <animated.div style={{ ...springProps,}}>
             <Box style={{
                     position: 'relative',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',
                     top:'0px',
                     right:0,
                     width:'100vw',
                     maxWidth:'600px'
                }}
                > 
                 
                 
                <Box
                      style={{ marginLeft:0,display:"flex",flexDirection:"column",justifyContent:'center',alignItems:'center'}}
                    >
                        <Typography
                            variant='h1'
                            component='h1'
                            style={{
                          
                                color: '#fff',
                                fontFamily: 'Open Sans',
                                fontWeight:'700',
                                lineHeight:'36px',
                                letterSpacing:'1%',
                                // lineHeight:36,
                                fontSize:'28px'
                                
                            }}
                        >
                            Podcasts
                        </Typography>
                        <Typography
                            variant='h2'
                            component='h1'
                            style={{
                                
                                // marginTop:{
                                //     xs:'60vh',
                                //     sm:'60vh',
                                //     md:'25vh',
                                //     lg:'25vh',
                                //     xl:'25vh'
                                // },
                                color: '#fff',
                                lineHeight:'24px',
                                fontFamily: 'Open Sans',
                                fontWeight: '600',
                                letterSpacing:'1%',
                                fontSize: '14px'
                            }}
                        >
                            based on 
                        </Typography>
                        <Typography
                            variant='h2'
                            component='h1'
                            sx={{
                                
                               
                                color: '#fff',
                                letterSpacing:'1%',
                                lineHeight:'36px',
                                fontFamily: 'Open Sans',
                                fontWeight: '700',
                                fontSize: '28px'
                            }}
                        >
                            Non-Fiction Books
                        </Typography>
                        <Typography
                            variant='p'
                            component='p'
                            style={{
                                py: 0,
                                lineHeight: 1.8, 
                                color: '#fff',
                                fontSize: '14px',
                                fontFamily: 'Open Sans',
                                fontWeight: '500',
                                maxWidth:'260px',
                                width: '90vw'
                            }}
                        >
                        {/* Gen AI-powered platform to absorb insightful knowledge from multiple books served in form of bite-sized content. Ready to Skoob it? Let's roll ! */}
                        </Typography>
                        
                    </Box>

                    <img
                        src={skoobHomemicLogo}
                        alt="headerImg"
                        style={{
                            width:'350px',
                            height:'414px'
                        }}
                    />

                    <Box sx={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
                        <Box sx={{borderRadius:'42px', display: 'flex',paddingX:'24px',paddingY:'14px',
                                 justifyContent: 'center', /* Center horizontally */
                                 boxShadow: '0px 2px 10px -5px #FFFFFF',
                                 alignItems: 'center',position:'relative',backgroundColor:'#FFFFFF',width:'70vw',maxWidth:'213px',height:'52px',cursor:"pointer"}}>
                               <a href={uri} onClick={handleClick} style={{textDecoration:'none',}} target="_blank">
                                 <Typography
                                     style={{ fontFamily: 'Open Sans',
                                     fontWeight:'600',color:'black',fontSize: '14px',lineHeight:'24px',letterSpacing:'1%'
                                   }}
                                   >Download App
                                 </Typography>
                               </a>
                            </Box>      
                    </Box>

                </Box>

                </animated.div>  
            </CustomBox>
        </Box >
    )
}

export default UpdatedHome