import React from "react";
import { Box, Typography } from "@mui/material";

const Terms = () => {
  return (
    <Box
      style={{ backgroundColor: "#181819",maxWidth:'600px', width: "100vw", paddingBottom: 50,position:'absolute',left:'50vw',top:0,transform:'translateX(-50%)' }}
    >
      <Typography
        sx={{
          fontSize: 44,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          paddingTop: 5,
          marginLeft:5,
          marginRight:5,
        }}
      >
        Terms of Use
      </Typography>
      <Typography
        sx={{
          fontSize: 20,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          marginTop: 5,
          textAlign: "center",
          marginLeft:5,
          marginRight:5,
        }}
      >
        1.Use of the Website
      </Typography>
      <Box sx={{   marginTop: 2 }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            marginLeft:5,
            marginRight:5,
          }}
        >
          1. Permitted Use: You may use the Website for informational and
          non-commercial purposes only. You may not use the Website in any
          manner that violates applicable laws or regulations.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            marginLeft:5,
            marginRight:5,
          }}
        >
          2. User Content: You may have the opportunity to submit content,
          comments, or feedback through the Website ("User Content"). By
          submitting User Content, you grant Skoob a non-exclusive, worldwide,
          royalty-free license to use, reproduce, modify, and display your User
          Content for the purpose of providing and promoting our services.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          marginLeft:5,
          marginRight:5,
        }}
      >
        2. Intellectual Property
      </Typography>
      <Box sx={{   marginTop: 2 }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            marginLeft:5,
            marginRight:5,
          }}
        >
          1. Ownership: The content, design, graphics, logos, and other
          materials on the Website are owned by Skoob and are protected by
          intellectual property laws. You may not use, reproduce, or distribute
          these materials without our prior written consent.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            marginLeft:5,
            marginRight:5,
          }}
        >
          2. Trademarks: "Skoob" and other trademarks displayed on the Website
          are the property of Skoob. You are prohibited from using these
          trademarks without our express permission.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          marginLeft:5,
          marginRight:5,
        }}
      >
        3. Disclaimer of Warranties
      </Typography>
      <Box sx={{   marginTop: 2 }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            marginLeft:5,
            marginRight:5,
          }}
        >
          1. The Website and its content are provided "as is" without any
          warranties of any kind, whether express or implied. Skoob does not
          warrant the accuracy, completeness, or reliability of the content on
          the Website.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          marginLeft:5,
          marginRight:5,
        }}
      >
        4. Limitation of Liability
      </Typography>
      <Box sx={{   marginTop: 2 }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            marginLeft:5,
            marginRight:5,
          }}
        >
          1. Skoob shall not be liable for any direct, indirect, incidental,
          consequential, or special damages arising out of or in connection with
          your use of the Website or reliance on its content.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          marginLeft:5,
          marginRight:5,
        }}
      >
        5. Links to Third-Party Websites
      </Typography>
      <Box sx={{   marginTop: 2 }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            marginLeft:5,
            marginRight:5,
          }}
        >
          1. The Website may contain links to third-party websites that are not
          owned or controlled by Skoob. We do not endorse or assume any
          responsibility for the content, privacy practices, or actions of
          third-party websites.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          marginLeft:5,
          marginRight:5,
        }}
      >
        6. Changes to the Terms
      </Typography>
      <Box sx={{   marginTop: 2 }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            marginLeft:5,
            marginRight:5,
          }}
        >
          1. Skoob reserves the right to modify or revise these Terms at any
          time. The date at the top of this page indicates when the Terms were
          last updated. Your continued use of the Website after any changes to
          the Terms constitutes your acceptance of the revised Terms.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          marginLeft:5,
          marginRight:5,
        }}
      >
        7. Governing Law
      </Typography>
      <Box sx={{   marginTop: 2 }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            marginLeft:5,
            marginRight:5,
          }}
        >
          1. These Terms shall be governed by and construed in accordance with
          the laws of Bangalore, India, without regard to its conflict of law
          principles.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          marginLeft:5,
          marginRight:5,
        }}
      >
        8. Contact Us
      </Typography>
      <Box sx={{   marginTop: 2 }}>
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 700,
            color: "#fff",
            textAlign: "center",
            marginLeft:5,
            marginRight:5,
          }}
        >
          1. If you have any questions, concerns, or inquiries regarding these
          Terms, please contact us at info@skoob.ai.
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          marginTop: 4,
          fontFamily: "Open Sans",
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          marginLeft:5,
          marginRight:5,
        }}
      >
        Thank you for using the Skoob website.
      </Typography>
    </Box>
  );
};

export default Terms;
