import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  memo,
} from "react";
import { BACKEND_URL, user_id } from "../url";
import LinearProgress from "@mui/material/LinearProgress";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; 
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import ContinueListeningBook from "../assets/ContinueListeningBook.png"
import ContinueListeningPlay from "../assets/ContinueListeningPlay.png"
import { Settings , AutoStories, PlayCircle} from '@mui/icons-material';
import FullPageLoader  from "../utils/pageLoader";

const HomeUser = () => {
  const [userData, setUserData] = useState({
    name: "",
    consumption_time: 0,
    target_consumption: 0,
    image: "",
    isLoading5: true,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [trendingstoryDataset, setTrendingstoryData] = useState({
    data: [],
    isLoading3: true,
  });
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [topCategoryData, setTopCategoryData] = useState({
    data: [],
    isLoading4: true,
  });
  const [trendingData, setTrendingData] = useState({
    data: [],
    isLoading1: true,
  });
  const [toppicsdata, setToppicsdata] = useState({
    data: [],
    isLoading2: true,
  });
  const [newSkoobs, setNewSkoobs] = useState({
    data: [],
    isloading: true,
  });
  const [clusterData, setClusterData] = useState({
    data: [],
    isLoading6: true,
  });
  const [continueData, setContinueData] = useState({
    data:[],
    isLoading7:true
  })

  // console.log("data from api is ------------------ ", 
  //   newSkoobs?.isloading, 
  //   trendingData?.isLoading1, 
  //   toppicsdata?.isLoading2,
  //   trendingstoryDataset.isLoading3,
  //   topCategoryData.isLoading4,
  //   userData.isLoading5,
  //   clusterData.isLoading6
  // );


  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };
  const settingsForTopCategory = {
    dots: false, // Show dots for navigation
    infinite: false, // Loop the carousel
    speed: 500, // Transition speed
    slidesToShow:
      windowWidth >= 800 && windowWidth <= 1000
        ? 5
        : windowWidth >= 600 && windowWidth <= 800
        ? 4
        : windowWidth <= 599
        ? 3.2
        : 6, // Number of items to show at once
    slidesToScroll: 1, // Number of items to scroll at a time
    arrows: true, // Disable previous/next buttons
  };

  const settingsForTopStory = {
    dots: false, // Show dots for navigation
    infinite: false, // Loop the carousel
    speed: 500, // Transition speed
    slidesToShow: windowWidth >= 900 && windowWidth < 1200 ? 2 : windowWidth > 1200 ? 2.5 : 1.5, // Number of items to show at once
    slidesToScroll: 1, // Number of items to scroll at a time
    arrows: true, // Disable previous/next buttons
  };

  const PodcastsForYou = {
    dots: false, // Show dots for navigation
    infinite: false, // Loop the carousel
    speed: 500, // Transition speed
    slidesToShow:
      windowWidth >= 800 && windowWidth <= 1000
        ? 5
        : windowWidth >= 600 && windowWidth <= 800
        ? 4
        : windowWidth <= 599
        ? 3.2
        : 6, // Number of items to show at once
    slidesToScroll: 1, // Number of items to scroll at a time
    arrows: true, // Disable previous/next buttons
  };

  const ContinueForYou = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow:6,
    initialSlide: 0,
    slidesToScroll: 1,
    arrows: true,
  };

  const handleRead = (item, index) => {
    console.log("Read button clicked for:", item, index);
    // Add your logic here
  };

  const handlePlay = (item, index) => {
    console.log("Play button clicked for:", item, index);
    // Add your logic here
  };

  const progressWidth =
    (((windowWidth * 3) / 5) *
      (userData?.consumption_time
        ? userData.consumption_time >= userData.target_consumption
          ? userData.target_consumption
          : userData.consumption_time
        : userData?.consumption_time === 0
        ? 0
        : 10)) /
    userData.target_consumption;
    
  
    const preloadImage = (url) => {
      console.log("pre load image worked -------------- ")
      const img = new Image();
      img.src = url;
      img.onload = () => console.log("Image loaded successfully.");
      img.onerror = () => console.error("Failed to load image.");
    };

  const HandleFetchUserData = async () => {
    await axios
      .get(`${BACKEND_URL}/user`, {
        headers: {
          Authorization: user_id,
        },
      })
      .then((response) => {
        if(response.data.img_url) preloadImage(response.data.img_url)
        console.log("user data is ++++++++:",response.data.name,response.data.consumption_time,response.data.img_url)
        if (response.data.name.length > 23) {
          setUserData({
            name: response.data.name.slice(0, 21) + "..",
            consumption_time: response.data.consumption_time,
            target_consumption: response.data.target_consumption * 60,
            image: response.data.img_url,
            isLoading5: false,
          });
          
        } else {
          setUserData({
            name: response.data.name,
            consumption_time: response.data.consumption_time,
            target_consumption: response.data.target_consumption * 60,
            image: response.data.img_url,
            isLoading5: false,
          });
        }
      })
      .catch((error) => {
        if (
          error.message === "Network Error" ||
          error.message === "Request Timeout Error" ||
          error.message === "Internal Server Error"
        ) {
        }
        setUserData((prevState) => ({
          ...prevState,
          isLoading5: false,
        }));
      });
  };

  const fetchDataforTopStories = async () => {
    await axios
      .get(`${BACKEND_URL}/story/recommend`, {
        headers: {
          Authorization: user_id,
        },
      })
      .then((response) => {
        let arr = [];
        response?.data.forEach((element) => {
          let item = {
            audio_url: element.story_audio_url,
            book: {
              author: element.book_author_name,
              id: element.story_id,
              img_url: element.book_img_url,
              name: element.book_name,
              thumb_url: element.book_thumb_url,
            },
            book_id: element.story_id,
            consumption: {
              last_mode: "",
              play_time_sec: 0,
              read_percentage: 0,
            },
            id: element?.story_id,
            play_time_in_sec: element?.story_play_time,
            summary: element?.skoob_summary,
            text: element?.story_text,
            title: element?.story_title,
            skoob_summary: element?.skoob_summary,
            skoob_tagline: element?.skoob_tagline,
            skoob_title: element?.skoob_title,
            skoob_img_url: element?.skoob_img_url,
            skoob_id: element?.skoob_id,
            skoob_thumb_url: element?.skoob_thumb_url,
            skoob_total_books: element?.skoob_total_books,
            total_time: element?.skoob_total_play_time,
            skoob_total_stories: element?.skoob_total_stories,
          };
          arr.push(item);
        });
        setTrendingstoryData({
          data: arr,
          isLoading3: false,
        });
      })
      .catch((error) => {
        if (
          error.message === "Network Error" ||
          error.message === "Request Timeout Error" ||
          error.message === "Internal Server Error"
        ) {
        }
      });
  };

  const handleCategoryData = async (refresh) => {
    await axios
      .get(`${BACKEND_URL}/home/category`, {
        headers: {
          Authorization: user_id,
        },
      })
      .then((response) => {
        setTopCategoryData({
          data: [...response.data],
          isLoading4: false,
        });
        setIsDataLoaded(true);
      })
      .catch((error) => {
        // console.error('Error handleCategoryData', error);
        if (
          error.message === "Network Error" ||
          error.message === "Request Timeout Error" ||
          error.message === "Internal Server Error"
        ) {
        }
      });
  };

  const HandleFetchTopSkoobData = async (refresh) => {
    await axios
      .get(`${BACKEND_URL}/collection/toppicksforyou`, {
        headers: {
          Authorization: user_id,
        },
      })
      .then((response) => {
        setToppicsdata({
          data: response.data,
          isLoading2: false,
        });
      })
      .catch((error) => {
        if (
          error.message === "Network Error" ||
          error.message === "Request Timeout Error" ||
          error.message === "Internal Server Error"
        ) {
        }
      });
  };

  const HandleFetchTrendinData = async (refresh) => {
    await axios
      .get(`${BACKEND_URL}/collection/trending`, {
        headers: {
          Authorization: user_id,
        },
      })
      .then((response) => {
        setTrendingData({
          data: response.data,
          isLoading1: false,
        });
      })
      .catch((error) => {
        // console.error('Error HandleFetchTrendinData', error.message);
        if (
          error.message === "Network Error" ||
          error.message === "Request Timeout Error" ||
          error.message === "Internal Server Error"
        ) {
        } else if (error.response.status === 401) {
        }
      });
  };

  const HandleFetchNewSkoobsData = async (refresh) => {
    await axios
      .get(`${BACKEND_URL}/collection/newrelease`, {
        headers: {
          Authorization: user_id,
        },
      })
      .then((response) => {
        setNewSkoobs({
          data: response.data,
          isloading: false,
        });
      })
      .catch((error) => {
        // console.error('Error HandleFetchNewSkoobsData', error.message);
        if (
          error.message === "Network Error" ||
          error.message === "Request Timeout Error" ||
          error.message === "Internal Server Error"
        ) {
        }
      });
  };

  const clusterFetching = async () => {
    await axios
      .get(`${BACKEND_URL}/home/cluster`, {
        headers: {
          Authorization: user_id,
        },
      })
      .then((response) => {
        setClusterData({
          data: response.data,
          isLoading6: false,
        });
      })
      .catch((error) => {
        if (
          error.message === "Network Error" ||
          error.message === "Request Timeout Error" ||
          error.message === "Internal Server Error"
        ) {
        }
      });
  };

  const HandleFetchContinueData = async (refresh) => {
    await axios
      .get(`${BACKEND_URL}/user/continue`, {
        headers: {
          Authorization: user_id,
        },
      })
      .then((response) => {
        setContinueData({
          data: response.data,
          isLoading7: false,
        });
      })
      .catch((error) => {
        // console.error('Error HandleFetchTrendinData', error.message);
        if (
          error.message === "Network Error" ||
          error.message === "Request Timeout Error" ||
          error.message === "Internal Server Error"
        ) {
        } else if (error.response.status === 401) {
        }
      });
  };

  useEffect(() => {
    HandleFetchUserData();
    handleCategoryData();
    fetchDataforTopStories();
    HandleFetchTopSkoobData();
    HandleFetchTrendinData();
    HandleFetchNewSkoobsData();
    clusterFetching();
    HandleFetchContinueData();
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const ItemForClusterCollections = (datas) => {
    let size = datas.data.collections.length > 4 ? 0.27 : 0.4;
    return (
      <>
        {datas.data.collections.map((item, index) => {
          return (
            <Box onClick={()=>handleNavigation(`/skoobdetails/${item.id}`)}
             key={index} sx={{ marginTop: 2,cursor:"pointer" }}>
              <img
                src={item.img_url}
                width={windowWidth * size}
                height={windowWidth * size}
                style={{ borderRadius: 10 }}
              />
            </Box>
          );
        })}
      </>
    );
  };


  const containerStyle = {
    backgroundImage: `url('/assets/background.jpg')`, // Direct reference to the public folder
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    textAlign: 'center',
  };

  const ContinueForYous = {
    dots: false,
    infinite: false,
    slidesToShow:6.5,
    initialSlide: 0,
    centerMode: false, 
    marginLeft:0,
    focusOnSelect: true,
  };


  console.log("data from api is ------------------ ", windowWidth)
  return (
    <>
    {userData.isLoading5 ? FullPageLoader() :
    <Box
      sx={{
        flex: 1,
        background: "#303030",
        paddingLeft: "1vw",
        paddingRight: "1vw",
      }}
    >
      {/* upper bar for holding user name and image and setting icon*/}
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          paddingTop:'0.5vw',
          justifyContent: "space-between"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{}}>
            <img
              src={userData.image}
              alt=""
              style={{ width: windowWidth >= 800 && windowWidth <= 1000
                ? "6vw"
                : windowWidth >= 600 && windowWidth <= 800
                ? "7vw"
                : windowWidth <= 599
                ? "8vw"
                :
                windowWidth >= 1200 ? "4vw"
                : "5vw", borderRadius: 100 }}
            />
          </Box>
          <Box sx={{ marginLeft: "1.5vw", alignItems: "center" }}>
            <Box>
              <Typography sx={{color:'white'}}>{`Hi ${userData.name}`}</Typography>
            </Box>
            <Box>
              <div
                style={{
                  position: "relative",
                  height: "12px",
                  borderRadius: "12px",
                  background: "linear-gradient(45deg, #F37815 0%, #E3B834 90%)",
                  width: `${progressWidth}px`,
                }}
              ></div>
            </Box>
          </Box>
        </Box>

        <Box sx={{}}>
          <Settings style={{ fontSize: windowWidth >= 1000 ? 40 : windowWidth <= 600 ? 30 : 35, color: 'white' }} />
        </Box>
      </Box>

      {/* section for top category */}
      <Box sx={{ marginTop: "4vw" }}>
        <Box>
          <Typography sx={{ color: "white" }}>
            Your Daily Podcasts Mix
          </Typography>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Slider {...settingsForTopCategory}>
            {topCategoryData.data.map((item, index) => {
              return (
                <Box 
                 sx={{cursor:"pointer"}}>
                  <img
                    src={item.img_url}
                    width={windowWidth * 0.27}
                    height={windowWidth * 0.27}
                  />
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>

      {/* section for top story */}
      <Box sx={{ marginTop: "4vw" }}>
        <Box>
          <Typography sx={{ color: "white" }}>
            Episodes for you under 15 mins
          </Typography>
        </Box>
        <Box 
        sx={{ marginTop: 2 ,cursor:"pointer"}}>
          <Slider {...settingsForTopStory}>
            {trendingstoryDataset.data.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    marginRight: 1,
                    marginBottom: 2,
                    borderRadius: "8px",
                    marginRight: 10,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      background: "black",
                      marginRight: 3,
                      borderRadius: "8px",
                      padding: 1,
                    }}
                  >
                    <Box sx={{ padding: 1}}>
                      <Box sx={{width: `${windowWidth < 900 ? windowWidth * 0.20 : windowWidth>1200 ? windowWidth * 0.14 : windowWidth * 0.18}px`, aspectRatio: '1 / 1', overflow: 'hidden', borderRadius: '4px'}}>
                        <img
                          src={item.skoob_thumb_url}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: "cover",
                            borderRadius: "4px",
                          }}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        paddingLeft: 1,
                        paddingRight: 1,
                        paddingBottom: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight:2,
                          justifyContent: "space-between",
                          height: `${windowWidth < 900 ? windowWidth * 0.20 : windowWidth>1200 ? windowWidth * 0.14 : windowWidth * 0.18}px`,
                          paddingBottom:2,
                        }}
                      >
                        <Box>
                          <Typography sx={{ color: "white", display: '-webkit-box',WebkitLineClamp: 3,WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis', fontSize: windowWidth < 900 ? '3vw' : windowWidth>900 && windowWidth < 1100 ? '2.5vw' : windowWidth>1100 ? '1.5vw' :'2vw'}}>
                            {item.title}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography sx={{ color: "grey" , fontSize: windowWidth < 900 ? '2.5vw' : windowWidth>900 && windowWidth < 1100 ? '2vw' : windowWidth>1100 ? '1.25vw' :'1.5vw' }}>{`from ${
                            item.skoob_title
                          } | ${Math.floor(
                            item.play_time_in_sec / 60
                          )} mins`}</Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingLeft: 1,
                          paddingRight: 1,
                        }}
                      >
                        <Box sx={{background:'grey', display:'flex', alignItems:'center',padding:'0.5vw', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:10}}>
                          <AutoStories style={{marginRight:2, fontSize: windowWidth >= 1000 ? 25 : windowWidth <= 600 ? 15 : 20, color: 'white' }} />
                          <Typography sx={{ color: "white", fontSize: windowWidth < 550 ? '2.5vw' : windowWidth > 550 && windowWidth < 700 ? '3vw' : windowWidth > 900 ? '2vw' : '3vw'}}>Read</Typography>
                        </Box>
                        <Box sx={{background:'grey', display:'flex', alignItems:'center',padding:'0.5vw', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:10}}>
                          <PlayCircle style={{marginRight:2, fontSize: windowWidth >= 1000 ? 25 : windowWidth <= 600 ? 15 : 20, color: 'white' }} />
                          <Typography sx={{ color: "white", fontSize: windowWidth < 550 ? '2.5vw' : windowWidth > 550 && windowWidth < 700 ? '3vw' : windowWidth > 900 ? '2vw' : '3vw'}}>Play</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>

      {/* section for Podcasts for you */}
      <Box
        sx={{
          marginTop:
            windowWidth >= 800 && windowWidth <= 1000
              ? "3vw"
              : windowWidth >= 600 && windowWidth <= 800
              ? "3.5vw"
              : windowWidth <= 599
              ? "4vw"
              : "2vw",
        }}
      >
        <Box>
          <Typography sx={{ color: "white" }}>Podcasts for you</Typography>
        </Box>
        <Box sx={{ marginTop: 1,cursor:"pointer" }}>
          <Slider {...PodcastsForYou}>
            {toppicsdata.data.map((item, index) => {
              return (
                <Box
                 onClick={()=>handleNavigation(`/skoobdetails/${item.id}`)}
                  sx={{
                    width: windowWidth * 0.27,
                    height:
                      windowWidth * windowWidth >= 800 && windowWidth <= 1000
                        ? 0.31
                        : windowWidth >= 600 && windowWidth <= 800
                        ? 0.31
                        : windowWidth <= 599
                        ? 0.31
                        : 0.31,
                  }}
                >
                  <Box sx={{ marginRight: 4 }}>
                    <img
                      src={item.img_url}
                      width={windowWidth * 0.27}
                      height={windowWidth * 0.27}
                      style={{ borderRadius: 10 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: 0.5,
                      }}
                    >
                      <Typography
                        sx={{
                          alignSelf: "center",
                          color: "white",
                          fontSize: { xs: 9, sm: 12, md: 15, lg: 17, xl: 20 },
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontSize: { xs: 8, sm: 11, md: 14, lg: 16, xl: 19 },
                        }}
                      >{`${item.total_stories} Stories from ${item.total_books} Books`}</Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>


      {/* section for Continue */}
      <Box
        sx={{
          marginTop:
            windowWidth >= 800 && windowWidth <= 1000
              ? "3vw"
              : windowWidth >= 600 && windowWidth <= 800
              ? "3.5vw"
              : windowWidth <= 599
              ? "4vw"
              : "2vw",
        }}
      >
        <Box>
          <Typography sx={{ color: "white" }}>Continue</Typography>
        </Box>
        <Box sx={{marginLeft:0,cursor:"pointer"}} >
          <Swiper
            slidesPerView={7}
            spaceBetween={10}
            slidesPerGroup={1}
            loop={false}
            centeredSlides={false}
            breakpoints={{
              320: {
                slidesPerView: 5, // 2 items for small screens (e.g., mobile)
              },
              480: {
                slidesPerView: 6, // 3 items for slightly larger screens
              },
              768: {
                slidesPerView: 7, // 4 items for tablet size
              },
              1024: {
                slidesPerView: 8, // 5 items for larger screens (e.g., tablets and small laptops)
              },
              1200: {
                slidesPerView: 9, // 6 items for larger screens
              },
              1400: {
                slidesPerView: 10, // 7 items for very large screens (e.g., desktop)
              }
            }}
          >
            {continueData.data.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  onClick={()=>handleNavigation(`/skoobdetails/${item.id}`)}
                  style={{
                    backgroundImage: `url(${item.img_url})`,
                    width: '100%',
                    paddingTop: '100%',
                    // height: '150px', // Adjust size as needed
                    borderRadius: '5px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <img
                    src={item?.last_mode == "Listening" ? ContinueListeningPlay :  ContinueListeningBook}
                    style={{
                      position: 'absolute',
                      top: '5%',
                      right: '5%',
                      width: '15%',
                      height: '15%',
                    }}
                  />
                  <div
                    style={{
                      height: '4px',
                      width: '100%',
                      background: 'white',
                      position: 'relative',
                      bottom: 0,
                    }}
                  >
                    <div
                      style={{
                        height: '100%',
                        width: `${(item.play_time_sec / item.play_time_in_sec) * 100}%`, // Calculate the width as a percentage
                        background: 'green'
                      }}
                    ></div>
                  </div>
                </div>
                <div style={{marginTop:1}}>
                  <Typography sx={{fontSize:'1vw', color:'white',  textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden', }}>{item?.category_name}</Typography>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>

      {/* section for Top 10 Podcasts */}
      <Box
        sx={{
          marginTop:
            windowWidth >= 800 && windowWidth <= 1000
              ? "3vw"
              : windowWidth >= 600 && windowWidth <= 800
              ? "3.5vw"
              : windowWidth <= 599
              ? "4vw"
              : "2vw",
        }}
      >
        <Box>
          <Typography sx={{ color: "white" }}>Top 10 Podcasts</Typography>
        </Box>
        <Box sx={{ marginTop: 1 }}>
          <Slider {...PodcastsForYou}>
            {trendingData.data.map((item, index) => {
              return (
                <Box
                onClick={()=>handleNavigation(`/skoobdetails/${item.id}`)}
                  sx={{
                    cursor:"pointer",
                    width: windowWidth * 0.27,
                    height:
                      windowWidth * windowWidth >= 800 && windowWidth <= 1000
                        ? 0.31
                        : windowWidth >= 600 && windowWidth <= 800
                        ? 0.31
                        : windowWidth <= 599
                        ? 0.31
                        : 0.31,
                  }}
                >
                  <Box sx={{ marginRight: 4 }}>
                    <img
                      src={item.img_url}
                      width={windowWidth * 0.27}
                      height={windowWidth * 0.27}
                      style={{ borderRadius: 10 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: 0.5,
                      }}
                    >
                      <Typography
                        sx={{
                          alignSelf: "center",
                          color: "white",
                          fontSize: { xs: 10, sm: 13, md: 16, lg: 18, xl: 21 },
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontSize: { xs: 9, sm: 12, md: 15, lg: 17, xl: 20 },
                        }}
                      >{`${item.total_stories} Stories from ${item.total_books} Books`}</Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>

      {/* section for New Podcasts */}
      <Box
        sx={{
          marginTop:
            windowWidth >= 800 && windowWidth <= 1000
              ? "3vw"
              : windowWidth >= 600 && windowWidth <= 800
              ? "3.5vw"
              : windowWidth <= 599
              ? "4vw"
              : "2vw",
        }}
      >
        <Box>
          <Typography sx={{ color: "white" }}>New Podcasts</Typography>
        </Box>
        <Box sx={{ marginTop: 1 }}>
          <Slider {...PodcastsForYou}>
            {newSkoobs.data.map((item, index) => {
              return (
                <Box
                  onClick={()=>handleNavigation(`/skoobdetails/${item.id}`)}
                  sx={{
                    cursor:"pointer",
                    width: windowWidth * 0.27,
                    height:
                      windowWidth * windowWidth >= 800 && windowWidth <= 1000
                        ? 0.31
                        : windowWidth >= 600 && windowWidth <= 800
                        ? 0.31
                        : windowWidth <= 599
                        ? 0.31
                        : 0.31,
                  }}
                >
                  <Box sx={{ marginRight: 4 }}>
                    <img
                      src={item.img_url}
                      width={windowWidth * 0.27}
                      height={windowWidth * 0.27}
                      style={{ borderRadius: 10 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: 0.5,
                      }}
                    >
                      <Typography
                        sx={{
                          alignSelf: "center",
                          color: "white",
                          fontSize: { xs: 10, sm: 13, md: 16, lg: 18, xl: 21 },
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontSize: { xs: 9, sm: 12, md: 15, lg: 17, xl: 20 },
                        }}
                      >{`${item.total_stories} Stories from ${item.total_books} Books`}</Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>

      {/* section for Cluster */}
      <Box
        sx={{
          marginTop:
            windowWidth >= 800 && windowWidth <= 1000
              ? "3vw"
              : windowWidth >= 600 && windowWidth <= 800
              ? "3.5vw"
              : windowWidth <= 599
              ? "4vw"
              : "2vw",
        }}
      >
        <Box>
          <Typography sx={{ color: "white" }}>Listen To</Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(${windowWidth <= 750 ? 1 : 2}, 1fr)`,
            gap: "2vw",
            marginTop: 2,
          }}
        >
          {clusterData.data.map((item, index) => {
            return (
              <Box
                sx={{ background: "black", padding: "3vw", borderRadius: 3 }}
                key={index + 20}
              >
                <Box sx={{ height: "8vw" }}>
                  <Typography
                    sx={{
                      
                      color: "white",
                      fontSize:
                        windowWidth >= 750 && windowWidth <= 1000
                          ? "3vw"
                          : windowWidth >= 600 && windowWidth <= 750
                          ? "4vw"
                          : windowWidth <= 599
                          ? "5vw"
                          : "2.5vw",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${
                      item?.collections?.length > 4 ? 3 : 2
                    }, 1fr)`,
                    gap: 2,
                  }}
                >
                  <ItemForClusterCollections data={item} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
    // {userData.isLoading5 && 
    // <Box><Typography>Loader</Typography></Box>
    // }
  }
    </>
  );
};

export default HomeUser;




// <Box sx={{ marginTop: 1}}>
//           <Slider {...ContinueForYou}>
//             {continueData.data.map((item, index) => {
//               return (
//                 <Box sx={{}}>
//                   <Box sx={{ marginRight: 4, width: windowWidth * 0.141, height: windowWidth * 0.161,}}>
//                    <div style={{
//                       backgroundImage: `url(${item.img_url})`,
//                       width: windowWidth * 0.141,
//                       height: windowWidth * 0.141,
//                       borderTopLeftRadius: 5,
//                       borderTopRightRadius: 5,
//                       backgroundSize: 'cover',
//                       backgroundPosition: 'center',
//                     }}>
//                      <img
//                         src={item?.last_mode == 'Listening' ? ContinueListeningPlay : ContinueListeningBook}
//                         style={{
//                           position: 'relative', // Position the image relative to the container
//                           top: '5%', // Adjust as needed
//                           left: '80%', // Adjust as needed
//                           width: windowWidth * 0.02,
//                           height: windowWidth * 0.02,
//                         }}
//                       />
//                       {/* <div style={{height:4, width:'100%', background:'white', position:'absolute', bottom:3}}>
//                         <div
//                           style={{
//                             height: '100%',
//                             width: `${(item.play_time_sec / item.play_time_in_sec) * 10}%`, // Calculate the width as a percentage
//                             background: 'green', // Adjust color as needed
//                           }}
//                         ></div>
//                       </div> */}
//                    </div>
//                    <div>
//                      <Typography sx={{fontSize:'1vw',marginTop:'0.2vw', color:'white', textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',  }}>{item?.category_name}</Typography>
//                    </div>
//                   </Box>
//                 </Box>
//               );
//             })}
//           </Slider>
//         </Box>