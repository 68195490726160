import React from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import readIcon from "../assets/read_icon_story.svg";
import playCircle from "../assets/play_circle_story.svg";
import { useLocation,useNavigate } from "react-router-dom";
import {slugs,hashids} from '../utils/strings';
import headphones from "../assets/headphones_grey.svg"

const StoryList = (props) => {

  const location = useLocation();
  const currentPath = location.pathname

  const navigate = useNavigate();

  const story_page_slug = slugs.story_page_slug;
  
  function gotoTrackPlayer(story,index){
    if(currentPath && currentPath.split('/')[1]==='skoobdetails'){
        // console.log("story encoded id is:++++",Buffer.from(story.id).toString('base64'))
        navigate(`/trackplayer/${props.id}/${story.id}-${story_page_slug}`,{
            state:{
                all_stories:props.stories,
                index:index
            }
        }
        );
    } 
    
  }

  function gotoReadPage(story,index){
    if(currentPath && currentPath.split('/')[1]==='skoobdetails'){
        navigate(`/readpage/${props.id}/${story.id}-${story_page_slug}`,{
            state:{
                all_stories:props.stories,
                index:index
            }
        }
    )
    } 
    
  }

  return (
  
    <Box
        sx={{
            // paddingLeft: 2,
            paddingX:1,
            paddingY:1,
            overflow:"hidden",
            backgroundColor: "#1E0E49",
            borderRadius: "12px",
            marginBottom: 3,
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center"
        }}
        >

    <Box
        sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: "1%", // Adjust gap here
            maxWidth: {
            xs: "99%",
            sm: "96%",
            md: "92%",
            lg: "88%",
            xl: "85%",
            },
            justifyContent: {
            xs: "center",
            sm: "center",
            md: "flex-start",
            },
        }}
    >
        
    {props.stories.map((story, index) => (
    
      <Grid
        item
        xs={12}  // Full width on extra small screens
        sm={12}   // 2 columns on small screens
        md={6}   // 2 columns on medium screens
        lg={6}   // 3 columns on large screens
        xl={6}   // 3 columns on extra large screens
        key={story.id}
        sx={{
          marginBottom: 1.5,
          padding: 1,
          width:
          {
            xs:"98%",
            sm:"98%",
            md:"49%"
          },
          backgroundColor: "#2C1659",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row", // Always horizontal
        //   gap: 0.8,  // Apply gap between cells
          alignItems: "start",
          overflow:"hidden"
        }}
      >
        {/* Left Section: Image */}
        <Box
          component="img"
          src={story?.book?.img_url || ""}
          alt={`Story ${index + 1}`}
          sx={{
            maxWidth: "100px", // Set a fixed width for the image
            height: "auto",
            borderRadius: "8px",
            backgroundColor: "#444",
            marginRight: 2, // Space between image and text
            flexShrink: 0,
          }}
        />

        {/* Right Section: Text and Actions */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            height: "100%",
            flexGrow: 1,  // Take remaining space for text
            overflow: "hidden", 
          }}
        >
          {/* Title and Episode */}
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: 12,
                sm: 14,
                md: 14,
                lg: 15,
                xl: 16,
              },
              fontFamily: "Open Sans",
              fontWeight: "medium",
              color: "#FFFFFF",
              marginBottom: 1,
            }}
          >
            Ep {index + 1}: {story.title}
          </Typography>

          <Box
            sx={{
             
              display: "flex",
              alignItems:"center",
              justifyContent:"space-between",
              gap: 2,
              // marginTop: 1,
              flexWrap: "nowrap",
              paddingInline:"2%"
            }}
          >
            <Typography
                
                sx={{
                 
                  color: "grey",
                  fontSize: {
                    xs: 11,
                    sm: 13,
                    md: 13,
                    lg: 15,
                    xl: 16,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: "400",
                 
                  maxWidth: {
                    xs:"60%",
                    sm:"70%",
                    md:"60%",
                    lg:"60%",
                    xl:"80%",
  
                  }, // Adjust the width as needed
                  textAlign: "left",
                  whiteSpace: "nowrap", // Prevent text from wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add an ellipsis for truncated text
                  flexShrink: 1,
                }}
              >
                From {story?.book?.name || ""}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row',justifyContent:"center",alignItems:"center",flexShrink: 0  }}>
              <Box sx={{
                
                height: {
                  xs: 14,
                  sm: 16,
                  md: 16,
                  lg: 18,
                  xl: 20,
                }
              }}>
                <img src={headphones} alt="headerImg" style={{ marginRight: '4.5px',color:"grey",fill:"grey" }} />
              </Box>
              <Typography
                variant="block1"
                component="block1"
                sx={{
                  color: "grey",
                  fontSize: {
                    xs: 12,
                    sm: 14,
                    md: 15,
                    lg: 16,
                    xl: 18
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                {Math.floor(story.play_time_in_sec/60)} m {story.play_time_in_sec%60} s
              </Typography>
            </Box>
          </Box>

          {/* Action Icons */}
          <Box
            sx={{
              display: "flex",
              gap: 2,
              marginTop: 1,
            }}
          >
            <IconButton
              onClick={() => {
                props.setStoryListen(true);
                props.setStoryRead(false);
                props.setcontinueStory(() => story);
                gotoTrackPlayer(story, index);
              }}
            >
              <Box
                component="img"
                src={playCircle}
                alt="Play Icon"
                sx={{ width: 28, height: 28 }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                props.setStoryListen(false);
                props.setStoryRead(true);
                props.setcontinueStory(story);
                gotoReadPage(story, index);
              }}
            >
              <Box
                component="img"
                src={readIcon}
                alt="Read Icon"
                sx={{ width: 28, height: 28 }}
              />
            </IconButton>
          </Box>
        </Box>
      </Grid>
    ))}
  </Box>
</Box>


  );
};

export default StoryList;
