import React from 'react';
import {
    Box,
    Typography,
} from '@mui/material'

import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web'
import ListenSkoobDoingAnything from '../assets/ListenSkoobDoingAnything.svg'

const WhenToSkoob = () => {
    const [ref, inView] = useInView({
      triggerOnce: true,
    });
    const springProps = useSpring({
      from: { opacity: 0, transform: 'translateY(15%)' },
      to: async (next) => {
        await next({ opacity: 1, transform: 'translateY(0%)' });
      },
      config: { duration: 500 },
      immediate: !inView,
      reset: true,
    });
    return (
        <animated.div id="WhenToSkoob" style={{
            ...springProps,
        }} >
        <Box ref={ref} sx={{ color: '#fff' }}>
            <Box
                sx={{
                    width: '100%',
                    maxWidth:'600px',
                    // border:'2px solid red',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '80px',
                }}
                >
                
                    {/* Podcasts Heading */}
                    <Typography
                    variant="h4"
                    component="h4"
                    sx={{
                        color: '#fff',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: '600',
                        lineHeight: '36px',
                        letterSpacing: '2%',
                        fontSize: '24px',
                        textAlign:'center',
                        paddingInline:{xs:'10%',sm:'2%'}
                    }}
                    >
                    Effortless Learning, Anytime, Anywhere
                    </Typography>

                    
                    <Box sx={{width:{xs:'90%',sm:'85%',md:'80%',display:'inline-block'}}}>
                        <img
                            src={ListenSkoobDoingAnything}
                            alt='listen skoob anytime'
                            style={{
                            width: '100%', 
                            height: 'auto', 
                            marginTop: '30px',
                            borderRadius: '16px', // Rounded corners
                            
                            }}
                        />
                    </Box>
    
            </Box>
        </Box>
    </animated.div>
    )
}

export default WhenToSkoob;