import {React,useEffect,useState,useRef, useContext} from 'react';
import { Box, styled, Typography,Skeleton } from "@mui/material";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web'
import '@fontsource/montserrat/500.css';
import axios from 'axios';
import ReactGA from "react-ga4";
import { UriContext } from '../pages/Main';

const WhySkoob = () => {
    const [Clusters,setClusters] = useState([]);
    const scrollContainerRef = useRef(null);
    const scrollRef = useRef(0);
    const [Loaders,setLoaders] = useState({clusters:false})
    // const [uri,setUri]=useState('');
    const uri = useContext(UriContext);

    const handleClick = (event) => {
        try {
          ReactGA.event({
            category: "download_clicks",
            action: "Download_Cluster",
            label: 'why_cluster_section',
          });
      
        } catch (error) {
          console.error("error:", error);
        }
      };

    const handleScroll = () => {
        if (!scrollContainerRef.current) return;
        const scrollLeft = scrollContainerRef.current.scrollLeft;
        const scrollAmount = scrollLeft <= 291 ? 292 : 285
        let index = Math.round(scrollLeft / scrollAmount); // Adjusted to `round` for better accuracy
        // console.log("Scrolled item index:", index,activeIndex, "ScrollLeft:", scrollLeft);
         if(index === Clusters?.length - 2 && scrollContainerRef.current.scrollLeft > index*284)
            {   
                index = Clusters?.length - 1
            }
        scrollRef.current = index;
        // colorDots(index)
        
        // setActiveIndex(index);
       };
    
    // const colorDots = (activeIndex)=> {
    //         const dots = document.querySelectorAll('.scroll-dot');
    //         dots.forEach((dot, index) => {
    //             dot.style.backgroundColor = index === activeIndex ? '#fff' : '#888';
    //         });
    //       }
    
    const scrollToIndex = (index) => {
        if (!scrollContainerRef.current || Clusters.length === 0) return;
        const screenWidth = window.innerWidth;
        
        const scrollAmount = index === 1 ? 291 : 292 + (index-1)*284; // Width of each item + margin
            
        // console.log("Scrolling to index:", index, "Scroll Amount:", scrollAmount);
        scrollContainerRef.current.scrollLeft = scrollAmount;
        scrollContainerRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
        });
    };
    
    useEffect(() => {
            if (!scrollContainerRef.current) return; // Ensure ref is assigned
            if (Clusters.length === 0) return;
            
            
                const interval = setInterval(() => {
                    // setActiveIndex((prevIndex) => {
                    
                    const nextIndex = (scrollRef.current + 1) % Clusters.length;
                    scrollToIndex(nextIndex);
                    scrollRef.current = nextIndex;
                    //   return nextIndex;
                    // });
                }, 5000);
        
            return () => clearInterval(interval); // Cleanup interval on unmount


        }, [Clusters]); // Dependency ensures updates

    async function FetchAllClusters() {
            setLoaders(prev => ({...prev,clusters:true}))
            const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
            const user_id = process.env.REACT_APP_USER_ID;
            await axios.get(`${BACKEND_URL}/home/all/cluster`, {
              headers: {
                'Authorization': user_id
              }
            },)
              .then(response => {
                // console.log(response.data);
                // console.log("skoobs has been fetched ++++++++++=============",response.data)
                setClusters(response.data);
            
                setLoaders(prev => ({...prev,clusters:false}))
          
              })
              .catch(error => {
                console.log("error is:",error)
                setLoaders(prev => ({...prev,clusters:false}))
              });
        }
    
        
    useEffect(()=>{

        FetchAllClusters()
        // setUri(localStorage.getItem('url'));

        },[])

    
    const [ref, inView] = useInView({
        triggerOnce: true, 
      });
      const springProps = useSpring({
        from: { opacity: 0, transform: 'translateY(50%)' },
        to: async (next) => {
          await next({ opacity: 1, transform: 'translateY(0%)' });
        },
        config: { duration: 500 },
        immediate: !inView, 
        reset: true,
      });

  return (

        <Box
                    sx={{
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'start',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop:'50px',
                        // border:'2px solid red'
                    }}
                            >
                            {/* Gradient Box */}
                            <Box
                                style={{
                                // border:'2px solid blue',
                                borderRadius: '50%',
                                maxHeight:'261px',
                                maxWidth:'261px',
                                width: '80vh',
                                height: '80vh',
                                position: 'absolute',
                                bottom: '-200px',
                                // border:'2px solid red',
                                background: `linear-gradient(
                                    to right,
                                    rgba(71, 224, 255) 0%,      /* #47E0FF with 50% opacity */
                                    rgba(106, 209, 233) 36%, /* #6AD1E9 with 50% opacity */
                                    rgba(146, 94, 255) 66%, /* #925EFF with 30% opacity */
                                    rgba(124, 55, 228) 100%    /* #7C37E4 with 50% opacity */    /* #7C37E4 with 50% opacity */
                                )`,
                                filter: 'blur(110px)',
                                backdropFilter: 'blur(50px)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                transform: 'translateZ(0)', 
                                willChange: 'transform, filter',
                                // border: '2px solid red', 
                                }}
                            />

                            {/* Text Box */}
                            <Box
                                id="Services"
                                style={{
                                position: 'relative',
                                top:'20px',
                                zIndex: 1, // Ensure text is above the gradient
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', // Align text to the start
                                justifyContent:'center',
                                // border:'2px solid red',
                                marginTop:'10px',
                                gap:'15px',
                                width:'90vw',
                                maxWidth:'500px',
                                overflow:'hidden',
                                paddingInline:'10px',
                                marginLeft: '0px',
                                }}
                            >
                                {/* Podcasts Heading */}
                                <Typography
                                variant="h2"
                                component="h2"
                                style={{
                                    color: '#fff',
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontWeight: 600,
                                    lineHeight: '36px',
                                    letterSpacing: '1%',
                                    fontSize: '24px',
                                    textAlign:'center'
                                }}
                                >
                                Find the Answers You've Been Searching For
                                </Typography>

                                {/* Subheading */}
                                <Typography
                                variant="h2"
                                component="p"
                                sx={{
                                    color: '#fff',
                                    lineHeight: '24px',
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontWeight: '500',
                                    letterSpacing: '5%',
                                    fontSize: '14px',
                                }}
                                >
                                Everything you need to know, all in one place
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                        zIndex: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '60px',
                                        gap: '3px',
                                        overflow: 'hidden',
                                        marginLeft: '0px',
                                        maxWidth:'600px',
                                        width:'100%',
                                    }}
                                    >

                                {
                                    Loaders?.clusters === true ? (
                                        <Box
                                            sx={{
                                                width: '90%',
                                                maxWidth: '600px',
                                                height: '250px',
                                                marginInline: '25px',
                                                marginBottom: '5px',
                                                border: '0.5px solid grey',
                                                borderRadius: '16px',
                                                backgroundSize: '200% 100%',
                                                animation: 'loadingAnimation 1.5s infinite linear',
                                            }}
                                        > <Skeleton  animation='wave' variant="rounded" width='100%' height='100%' />
                                        </Box>
                                    ) : (
                                        // Scrollable Container
                                        <Box
                                            ref={scrollContainerRef}
                                            className="hide-scrollbar"
                                            style={{
                                                width: '100%',
                                                overflowX: 'auto',
                                                whiteSpace: 'nowrap',
                                                paddingLeft: '26px',
                                                scrollBehavior: 'smooth',
                                                scrollbarWidth: "none",
                                                "&::-webkit-scrollbar": { display: "none" },
                                            }}
                                            onScroll={handleScroll}
                                        >
                                            {/* Render Clusters */}
                                            {Array.isArray(Clusters) &&
                                                Clusters?.map((cluster, index) => (
                                                    <Box
                                                        key={cluster.id}
                                                        sx={{
                                                            display: 'inline-block',
                                                            marginRight: index === Clusters?.length - 1 ? '26px' : '20px',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                       <a href={uri} onClick={handleClick} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={cluster?.updated_img_url}
                                                            alt={cluster?.name}
                                                            style={{
                                                                width: '264px',
                                                                height: '264px',
                                                                borderRadius: '16px',
                                                            }}
                                                        />
                                                        </a>
                                                    </Box>
                                                ))}
                                        </Box>
                                    )
                                    }
                                                                
                                    
                                    {/* <Box
                                        sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '4px',
                                        marginTop: '15px',
                                        }}
                                    >
                                        {(Array.isArray(Clusters)) && Clusters.map((_, index) => (
                                        <Box
                                            className="scroll-dot"
                                            key={index}
                                            onClick={() => scrollToIndex(index)} // Scroll to item on dot click
                                            sx={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: scrollRef.current === index ? '#fff' : '#888',
                                            cursor: 'pointer',
                                            transition: 'background-color 0.3s',
                                            }}
                                        />
                                        ))}

                                    </Box> */}

                                </Box>
                        </Box>
   
  )
}

export default WhySkoob