import React from "react";
import {
  Box,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import Instagram from "../assets/skill-icons_instagram.svg";
import Linkedin from "../assets/skill-icons_linkedin.svg";
import Facebook from "../assets/logos_facebook.svg";
import SkooobLogo from '../assets/SkoobLogo.png';


const UpdatedFooter = () => {
  
  const itemList =[
        {
          text : "Home",
          to : "Home"
        },
        {
          text : "About",
          to : "Features"
        }
      ]
  

  return (
    <Box style={{maxWidth:'600px',width:'100vw',backgroundColor:'#181819',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'20px',paddingBottom:'140px'}}>
       <Box sx={{width:{xs:'120px',sm:'150px'}}}>
          <img
                src={SkooobLogo}
                alt="SkoobLogo"
                style={{
                width:'100%',
                height:'auto',
              }} />
        </Box>

        
        <List sx={{marginTop:'20px'}}>
          {itemList.map((item) => {
            const { text, to } = item;
            return (
              <ListItem key={text} sx={{marginTop:'10px'}}>
                <ScrollLink
                  to={to}
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-200}
                  activeClass="active"
                  initialClassName="active"
                >
          
                   <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Open Sans",
                        fontSize: '18px',
                        fontWeight: "600",
                        lineHeight:'21.78px',
                        cursor:'pointer'
                      }}
                    >
                      {text}
                    </Typography>
    
                </ScrollLink>
              </ListItem>
            );
          })}
        </List>

        <Box sx={{ marginLeft: "10px", display:'flex',flexDirection:'row',gap:'50px',justifyContent:'space-around',marginTop:'30px'}}>
     
            <a href="https://www.instagram.com/skoob.ai?igsh=Y3AzYm11dHh0Nmx5" rel="noopener noreferrer" target="_blank">
              <img
                src={Instagram}
                alt="Instagram"
                width="30px"
                height="30px"
              />
            </a>
            <a href="https://www.linkedin.com/company/skoob-ai/" rel="noopener noreferrer" target="_blank">
              <img
                src={Linkedin}
                alt="Linkedin"
                width="32px"
                height="32px"
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
            </a>
            <a href="https://www.facebook.com/people/Skoob-AI/pfbid0DSZ6wNKVYtU9T19t4KtpF3CXZsGou1eGJumUy94i6k2YbePLXCPvFPhdavzT7heYl/" rel="noopener noreferrer" target="_blank">
              <img
                src={Facebook}
                alt="Facebook"
                width="30px"
                height="30px"
              />
            </a>
          </Box>
          <Box sx={{ marginLeft: "10px", display:'flex',flexDirection:'row',gap:'50px',justifyContent:'space-around',marginTop:'20px'}}>
          <a style={{ textDecoration: "none" }} href="/privacy" >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontSize: '18px',
                fontWeight: "600",
                marginTop: "15px",
                lineHeight:'21.78px',
              }}
            >
              Privacy Policy
            </Typography>
          </a>

          <a style={{ textDecoration: "none" }} href="/terms">
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontSize: '18px',
                fontWeight: "600",
                marginTop: "15px",
                lineHeight:'21.78px',
              }}
            >
              Terms of use
            </Typography>
          </a>
          </Box>

    </Box>
  );
};
export default UpdatedFooter;
