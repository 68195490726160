
import {
  Box,
  Typography,
  CircularProgress,
  
} from "@mui/material";


const FullPageLoader = () => {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position:"fixed"
        }}
      >
        <CircularProgress color="primary" />
        <Typography
          variant="h6"
          sx={{ marginTop: 2, color: "black", fontWeight: 500 }}
        >
          Loading...
        </Typography>
      </Box>
    );
  };

export default FullPageLoader;