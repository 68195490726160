import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  Typography,
  styled,
  ListItemButton,
} from '@mui/material';
import DrawerItem from './DrawerItem';
import { Link as ScrollLink} from 'react-scroll';
import SkooobIcon from '../assets/SkoobIcon.svg';
import { useNavigate } from 'react-router-dom';
const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: 'Josefin Sans',
  marginRight: '5vw',
});

const ListMenu = styled(List)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    fontFamily: 'Josefin Sans',
  },
}));

const itemList = [
  {
    text: 'Home',
    to: 'Home', // replace with the ID of the target component
  },
  {
    text: 'About',
    to: 'Features', // replace with the ID of the target component
  },
  {
    text: 'Features',
    to: 'AboutMindverse', // replace with the ID of the target component
  },
  {
    text: 'Why',
    to: 'Services', // replace with the ID of the target component
  },
  {
    text: 'How',
    to: 'HowItWorks', // replace with the ID of the target component
  },
];

const Navbar = () => {
  const [uri,setUri]=useState('');
  useEffect(()=>{
    setUri(localStorage.getItem('url'));
  },[])

  const navigate = useNavigate();
  return (
    <AppBar
      component="nav"
      position="fixed"
      sx={{
        backgroundColor: '#1D1956',
      }}
    >
      <StyledToolbar sx={{ }}>
         <Box sx={{
                    marginLeft:{xs:'0vw',sm:'0vw',md:'0vw',lg:'5vw',xl:'5vw'},
                    width:{xs:'25vw',sm:'20vw',md:'20vw',lg:'15vw',xl:'15vw'},
                    height:{xs:'10vh',sm:'10vh',md:'10vh',lg:'13vh',xl:'15vh'}
                }}
                >
             <img
                        src={SkooobIcon}
                        alt="SkoobLogo"
                        style={{
                        width:'100%',
                        height:'100%',
            }}
          />
        </Box>
        <Box sx={{borderRadius:'6px', display: 'flex',
                justifyContent: 'center', /* Center horizontally */
                alignItems: 'center',position:'absolute',right:{xs:53,sm:63,md:63,lg:'45vw',xl:'40vw'},backgroundColor:'#FFFFFF',width:'110px',height:'33px',}}>
              <a href={uri} style={{textDecoration:'none',}} target="_blank">
                <text
                    style={{ fontFamily: 'Open Sans',
                    fontWeight:'600',color:'#342067',fontSize: 12
                  }}
                  >Download Now
                </text>
              </a>
        </Box>
        <Box sx={{ display: { xs: 'block',sm:'block', md: 'none',lg:'none'},fontFamily: 'Josefin Sans'  }}>
          <DrawerItem />
        </Box>
        <ListMenu>
          {itemList.map((item) => {
            const { text, to } = item;
            return (
              <ListItem key={text}>
                
                <ScrollLink
                  to={to}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-200}
                  activeClass="active" // Apply a class to the active link
                >
                  <ListItemButton
                    onClick={()=>navigate('/')}
                    sx={{
                      color: '#fff',
                      fontSize: 18,
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#0d6efd',
                      },
                    }}
                  >
                    <Typography sx={{   fontFamily: 'Open Sans',
                                fontWeight: 700,fontSize: 18 }}>{text}</Typography>
                  </ListItemButton>
                </ScrollLink>
              </ListItem>
            );
          })}
        </ListMenu>
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar;
