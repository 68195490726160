import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Background from "../assets/Background.svg";
import Skoobdetaildownload from "../assets/Skoobdetaildownload.svg";
import contract from "../assets/contract.svg"
import newsstand from "../assets/newsstand.svg"
import headphones from "../assets/headphones.svg"
import axios from 'axios';
import TrackPlayer from "./TrackPlayer";
import ReadPage  from "./ReadPage";
import StoryList from './CustomStoryList'
import FullPageLoader from '../utils/pageLoader'


const SkoobDetail = (props) => {
  const [uri, setUri] = useState('');
  const [stories,setStories] = useState([]);
  const [storyListen,setStoryListen] = useState(false);
  const [storyRead,setStoryRead] = useState(false);
  const [continueStory,setcontinueStory]=useState({});
  const [isReadMore,setReadMore] = useState(false);
  const [skoob, setSkoob] = useState({});
  const [summaryCharLimit,setsummaryCharLimit] = useState(200);
  const [truncatedSummary,setTruncatedSummary] = useState(200);
  const [loaders,setLoaders] = useState({storyLoader:false,collectionLoader:false})

  const theme = useTheme();

  const toggleReadMore = () => {
    setReadMore((prevState) => !prevState);
  };
  
  
    
  const truncateText = (text,charLimit) => {
    if (!text) return "";
      return `${text.slice(0, charLimit)}...`;
   
  };

  
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMd = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLg = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXl = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    console.log("theme changed\n")
    let charLimit = 200; // Default word limit
    if (isXs) charLimit = 100;
    else if (isSm) charLimit = 130;
    else if (isMd) charLimit = 220;
    else if (isLg) charLimit = 350;
    else if (isXl) charLimit = 350;
    
    console.log("char limit is:",charLimit,isXs,isSm,isMd,isLg,isXl);
    setsummaryCharLimit(charLimit);
    if(skoob){
      setTruncatedSummary(truncateText(skoob.summary,charLimit));
    }

  }, [isXs, isSm, isMd, isLg, isXl])


  useEffect(() => {
    window.scrollTo(0, 0);
    setUri(localStorage.getItem('url'));
  }, [])
  
  useEffect(() => {
    
    const FetchCollectionDetails = async () => {
      setLoaders(prev => ({...prev,collectionLoader:true}))
      const BACKEND_URL = localStorage.getItem('BACKEND_URL');
      const user_id = localStorage.getItem('unique_key');
      await axios.get(`${BACKEND_URL}/collection`, {
        params: { collection_id: props.id }, headers: {
          'Authorization': user_id
        }
      },)
        .then(response => {
          // console.log(response.data[0]);
          console.log("skoobs has been fetched ++++++++++=============",response.data)
          setSkoob(response.data[0]);
          setTruncatedSummary(truncateText(response.data[0].summary));
          setLoaders(prev => ({...prev,collectionLoader:false}))
    
        })
        .catch(error => {
          // console.log("error is:",error)
          setLoaders(prev => ({...prev,collectionLoader:false}))
        });
    }


   
    const FetchStoriesDetails = async ()=>{
      const BACKEND_URL= localStorage.getItem('BACKEND_URL');
      const user_id= localStorage.getItem('unique_key');
      setLoaders(prev => ({...prev,storyLoader:true}))
      await axios.get(`${BACKEND_URL}/story/all/${props.id}`, { headers: {
        'Authorization': user_id
      } },)
          .then(response => {
            console.log("stories has been fetched ++++++++++=============",response.data)
            console.log("done +++++++++++++")
            // console.log("reponse is:+++++++++",response.data);
            setStories(response.data.stories);
            setLoaders(prev => ({...prev,storyLoader:false}))
          
            response.data.stories.forEach(myFunction)
            function myFunction(value, index, array){
              // if(value.id === props.story_id){
                // setStory(value)
             
              // }
            }
          })
        .catch(error => {
          setLoaders(prev => ({...prev,storyLoader:false}))
        })
    }
    FetchCollectionDetails();
    FetchStoriesDetails();
  }, [props.id])


   

  return (
    <>
    {loaders.storyLoader && loaders.collectionLoader ? FullPageLoader() : storyListen ? <TrackPlayer story={continueStory} /> : storyRead ? <ReadPage  story={continueStory} /> :
    <>
      {skoob && <Box sx={{
        backgroundColor: "#1E0E49",
        marginTop: {
          xs: "12vh",
          sm: "10vh",
          md: "10vh",
          lg: "10vh",
          xl: "10vh"
        }
      }}>
        <Box sx={{ position: 'relative', marginTop: -3 }}>
          <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: {
              xs: "2.5vh",
              sm: "5vh",
              md: "5vh",
              lg: "10vh",
              xl: "10vh"
            }
           }}>
            <img src={skoob.img_url} alt="headerImg" style={{ maxWidth: "70vw",maxHeight:"70vh", borderRadius: '12px' }}
                sx={{
                  border:"10px solid red",
                  padding:"40px"
                }}
              
            />
          </Box>
        </Box>

        
        <Box sx={{ marginTop: "2vh", position: 'relative' }}>
          {/* <img src={Background} alt="headerImg" style={{ width: "100vw" }} /> */}
          <Typography
            variant="p"
            component="p"
            sx ={{
              position: 'relative',
              // top: 0,
              // marginTop: '1vh',
              color: "#fff",
              fontSize: {
                xs: 16,
                sm: 20,
                md: 24,
                lg: 28,
                xl: 32,
              },
              fontFamily: "Open Sans",
              fontWeight: '700',
              width: "100vw",
              textAlign: 'center',
            }}
          >
            {skoob.title}
          </Typography>
          <Typography
            variant="p"
            component="p"
            sx={{
              
              marginTop: '5px',
              color: "#fff",
              fontSize: {
                xs: 13,
                sm: 15,
                md: 16,
                lg: 18,
                xl: 18,
              },
              fontFamily: "Open Sans",
              fontWeight: '300',
              width: "100vw",
              textAlign: 'center',
            }}
          >
            {skoob.tagline}
          </Typography>
          
          <Box sx={{  display: 'flex', flexDirection: 'row', marginTop: '10px', justifyContent: 'center', width: '80vw', marginLeft: '10vw' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row',justifyContent:"center",alignItems:"center" }}>
              <Box sx={{
                height: {
                  xs: 14,
                  sm: 15,
                  md: 16,
                  lg: 18,
                  xl: 20,
                }
              }}>
                <img src={headphones} alt="headerImg" style={{ marginRight: '4.5px' }} />
              </Box>
              <Typography
                variant="block1"
                component="block1"
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: 14,
                    sm: 15,
                    md: 16,
                    lg: 18,
                    xl: 20
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                {Math.floor(skoob.total_time / 60)} mins
              </Typography>
            </Box>
            <Box sx={{ marginLeft: '5vw', display: 'flex', flexDirection: 'row',justifyContent:"center",alignItems:"center"  }} >
              <Box sx={{
                height: {
                  xs: 14,
                  sm: 15,
                  md: 16,
                  lg: 18,
                  xl: 20,
                }
              }}>
                <img src={newsstand} alt="headerImg" style={{ marginRight: '4.5px' }} />
              </Box>
              <Typography
                variant="block1"
                component="block1"
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: 14,
                    sm: 15,
                    md: 16,
                    lg: 18,
                    xl: 20
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                {skoob.total_books} Books
              </Typography>
            </Box>
            <Box sx={{ marginLeft: '5vw', display: 'flex', flexDirection: 'row',justifyContent:"center",alignItems:"center"  }} >
              <Box sx={{
                height: {
                  xs: 14,
                  sm: 15,
                  md: 16,
                  lg: 18,
                  xl: 20,
                }
                }}>
                <img src={contract} alt="headerImg" style={{ marginRight: '4.5px' }} />
              </Box>
              <Typography
                variant="block1"
                component="block1"
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: 14,
                    sm: 15,
                    md: 16,
                    lg: 18,
                    xl: 20
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                {skoob.total_stories} Stories
              </Typography>
            </Box>
          </Box>

         
          <Box 
            onClick={toggleReadMore}
            style={{marginTop:"20px" ,paddingInline:"20px",textAlign:"left"}}
            sx={{maxWidth:{
              xs:'98%',
              sm:'98%',
              md:'90%'
            },
             
              position: "relative",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Typography
                variant="block1"
                component="block1"
                sx={{
                  lineHeight:1.5,
                  color: "#fff",
                  fontSize: {
                    xs: 14,
                    sm: 15,
                    md: 15,
                    lg: 17,
                    xl: 19,
                  },
                  
                  fontFamily: "Open Sans",
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                {isReadMore ? skoob.summary : truncatedSummary} 
              </Typography>

              <Typography
                variant="block1"
                component="block1"
                sx={{
                  cursor:"pointer",
                  lineHeight:1.5,
                  color: "#fff",
                  fontSize: {
                    xs: 14,
                    sm: 15,
                    md: 15,
                    lg: 17,
                    xl: 19,
                  },
                  
                  fontFamily: "Open Sans",
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
               { isReadMore ? ' Read Less' : 'Read More'}
              </Typography>
          </Box>
          
         { stories &&  <Box sx={{marginTop:"20px",display:"flex",justifyItems:"center",flexDirection:"column" }} >
              <Typography
                variant="h3"
                component="h1"
                sx ={{
                  paddingInline:"15px",
                  marginBottom: 1,
                  color: "#fff",
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                  fontSize: {
                    xs: 15,
                    sm: 16,
                    md: 18,
                    lg: 20,
                    xl: 22,
                  },
                 
                  fontFamily: "Open Sans",
                  fontWeight: '700',
                  maxWidth:
                    {
                        xs:"98%",
                        sm:"98%",
                        md:"90%"
                    },
                  textAlign: 'left',
                }}
              >
              Stories : 
              </Typography>

              <StoryList
              id={props.id}
              stories={stories} 
              setStoryListen={setStoryListen} 
              setStoryRead={setStoryRead} 
              setcontinueStory={setcontinueStory} 
              />
          
          </Box> 
          
          }


        </Box>



        <Box sx={{ marginTop: "1vh", position: 'relative'}}>
          <img src={Background} alt="headerImg" style={{ width: "100vw" }} />
          
          <Box sx={{
            width: '100vw', height: '0.5px', backgroundColor: '#FFFFFF', position: 'absolute', top: 0, marginTop: {
              xs: '0vh',
              sm: '0vh',
              md: '0vh',
              lg: '0vh',
              xl: '0vh',
              // xs: '10vh',
              // sm: '13vh',
              // md: '18vh',
              // lg: '28vh',
              // xl: '33vh',
            }
          }}></Box>
          <Typography
            variant="p"
            component="p"
            sx={{
              position: 'absolute',
              top: 0,
              marginTop: {
                xs: '3vh',
                sm: '3vh',
                md: '3vh',
                lg: '3vh',
                xl: '3vh',
                // xs: '12vh',
                // sm: '15vh',
                // md: '20vh',
                // lg: '30vh',
                // xl: '35vh',
              },
              color: "#fff",
              fontSize: {
                xs: 18,
                sm: 24,
                md: 30,
                lg: 36,
                xl: 42,
              },
              fontFamily: "Open Sans",
              fontWeight: '300',
              width: "80vw",
              textAlign: 'center',
              marginLeft: '10vw',
              marginRight: '10vw'
            }}
          >
            Download Skoob and Immerse Yourself in '{skoob.title}'
          </Typography>
          <Box sx={{
            position: 'absolute', bottom: {
              xs: '12vh',
              sm: '15vh',
              md: '25vh',
              lg: '30vh',
              xl: '35vh',
            }, width: '100vw',
          }}>
            <img src={Skoobdetaildownload} alt="headerImg" style={{ width: "50vw", marginLeft: '25vw' }} />
          </Box>
          <a href={uri} style={{ textDecoration: 'none', }} target="_blank"  >
            <Box sx={{
              position: 'absolute', display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', bottom: {
                xs: '30px',
                sm: '30px',
                md: '30px',
                lg: '30px',
                xl: '30px',
              }, borderRadius: '6px', backgroundColor: '#FFFFFF', width: '90vw', marginLeft: '5vw', height: '45px', marginRight: '5vw',
            }}>
              <text
                style={{
                  fontFamily: 'Open Sans',
                  fontWeight: '600', color: '#342067', fontSize: {
                    xs: 14,
                    sm: 16,
                    md: 18,
                    lg: 20,
                    xl: 22,
                  },
                }}
              >Download Now
              </text>
            </Box>
          </a>
        </Box>
      </Box>}
        
    </>
              }  
     </>     
  );
};

export default SkoobDetail;
